import React, { useEffect, useState } from "react";

import { Box } from "@mui/system";
import DeviceGroupsTable from "./components/DeviceGroupsTable";
import DeviceListTable from "./components/DeviceListTable";
import { Stack } from "@mui/material";
import OperationSystemTab from "./components/OperationSystemTab";
import TenantSelection from "../Components/TenantSelection";
import DeviceGroupDialog from "./components/DeviceGroupDialog";
import useAxios from "../../../hooks/useAxios";
import { NGROK } from "../../../APIs";
import { fetchDevices } from "./api";
import { myLocalStorage } from "../../../components/StorageHelper";

const EndpointsGroups = () => {
  const storageOS = myLocalStorage.getItem("selectedOS") || "WINDOWS";
  const tenant = myLocalStorage.getItem("latestTenant");

  const [osValue, setOsValue] = useState(storageOS);
  const [selectedTenant, setSelectedTenant] = useState(tenant);
  const [selectedGroup, setSelectedGroup] = useState(null);
  const [deviceList, setDeviceList] = useState([]);
  const [profilesList, setProfilesList] = useState([]);
  const [deviceGroups, setDeviceGroups] = useState([]);

  const { data: profilesData } = useAxios(
    `${NGROK}/api/profiles/inherited?tenantName=${selectedTenant?.tenantName}`
  );

  useEffect(() => {
    const fetchData = async () => {
      const devices = await fetchDevices(selectedTenant?.tenantName, osValue);
      setDeviceList(devices);
    };

    fetchData();
  }, [selectedTenant, osValue]);

  useEffect(() => {
    setProfilesList(profilesData);
  }, [profilesData, selectedTenant, osValue]);

  return (
    <Stack spacing={2}>
      <Box display={"flex"} justifyContent={"space-between"}>
        <TenantSelection
          selectedTenant={selectedTenant}
          setSelectedTenant={setSelectedTenant}
        />
        <DeviceGroupDialog
          tenantName={selectedTenant?.tenantName}
          deviceList={deviceList}
          setDeviceList={setDeviceList}
          os={osValue}
          profilesList={profilesList}
          setDeviceGroups={setDeviceGroups}
        />
      </Box>
      <OperationSystemTab
        content={
          <Box display={"flex"} justifyContent={"space-between"}>
            <DeviceGroupsTable
              deviceGroups={deviceGroups}
              setDeviceGroups={setDeviceGroups}
              tenantName={selectedTenant?.tenantName}
              os={osValue}
              setSelectedGroup={setSelectedGroup}
              selectedGroup={selectedGroup}
              deviceList={deviceList}
              setDeviceList={setDeviceList}
              profilesList={profilesList}
            />
            <DeviceListTable
              os={osValue}
              deviceList={deviceList}
              selectedGroup={selectedGroup}
            />
          </Box>
        }
        osValue={osValue}
        setOsValue={setOsValue}
      />
    </Stack>
  );
};

export default EndpointsGroups;
