import React, { useEffect, useState } from "react";
import styled, { withTheme } from "styled-components/macro";

import Chart from "react-apexcharts";
import {
  Card as MuiCard,
  CardContent,
  CardHeader,
  IconButton,
  Button,
  CircularProgress,
} from "@mui/material";
import { spacing } from "@mui/system";
import { MoreVertical } from "react-feather";
import ReactApexChart from "react-apexcharts";
const Card = styled(MuiCard)(spacing);
const ChartWrapper = styled.div`
  height: 378px;
`;
const TimeBasedSeries = ({ data }) => {
  const [auditData, setAuditData] = useState(null);
  const [series, setSeries] = useState(null);
  const [options, setOptions] = useState(null);
  const [loading, setLoading] = useState(true);

  const getTimeSeriesData = (data) => {
    if (data) {
      const now = new Date();
      const lastWeek = new Date(
        now.getFullYear(),
        now.getMonth(),
        now.getDate() - 7
      );
      const filteredData = data.filter((item) => {
        const timestamp = new Date(item.time);
        return timestamp >= lastWeek && timestamp <= now;
      });
      const counts = {};
      filteredData.forEach((item) => {
        const dateStr = new Date(item.time).toLocaleDateString();
        counts[dateStr] = (counts[dateStr] || 0) + 1;
      });
      for (let i = 0; i < 7; i++) {
        const date = new Date(
          now.getFullYear(),
          now.getMonth(),
          now.getDate() - i
        );
        const dateStr = date.toLocaleDateString();
        if (!counts[dateStr]) {
          counts[dateStr] = 0;
        }
      }
      const labels = Object.keys(counts);
      const dataVal = Object.values(counts);
      var series = [
        {
          name: "",
          data: dataVal,
        },
      ];
      var options = {
        chart: {
          type: "area",
          height: 350,
          stacked: true,

          toolbar: {
            show: false,
          },
        },

        xaxis: {
          categories: labels,
        },
        dataLabels: {
          enabled: false,
        },
        yaxis: {
          labels: {
            formatter: function (value) {
              return Number.isInteger(value) ? value : "";
            },
          },
        },
      };
      setAuditData(data);
      setSeries(series);
      setOptions(options);
      setLoading(false);
    }
  };

  useEffect(() => {
    getTimeSeriesData(data);
  }, [data]);

  return (
    <>
      <Card>
        <CardHeader
          title="Events Timeline"
          action={
            <IconButton aria-label="settings" size="large">
              <MoreVertical />
            </IconButton>
          }
        />
        <CardContent>
          <ChartWrapper>
            {loading ? (
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "100%",
                }}
              >
                <CircularProgress />
              </div>
            ) : (
              auditData !== null && (
                <ReactApexChart
                  options={options}
                  series={series}
                  type="area"
                  height={350}
                />
              )
            )}
          </ChartWrapper>
        </CardContent>
      </Card>
    </>
  );
};

export default TimeBasedSeries;
