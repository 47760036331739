import React from "react";
import ReactApexChart from "react-apexcharts";
import styled, { withTheme } from "styled-components/macro";

import { Card as MuiCard, CardContent, CardHeader } from "@mui/material";
import { spacing } from "@mui/system";
const Card = styled(MuiCard)(spacing);
const ChartWrapper = styled.div`
  height: 378px;
`;
const AlertCharts = ({ alertJson }) => {
  const now = new Date();
  const lastWeek = new Date(
    now.getFullYear(),
    now.getMonth(),
    now.getDate() - 7
  );
  const filteredData = alertJson.filter((item) => {
    const timestamp = new Date(item.time);
    return timestamp >= lastWeek && timestamp <= now;
  });
  const counts = {};
  filteredData.forEach((item) => {
    const dateStr = new Date(item.time).toLocaleDateString();
    counts[dateStr] = (counts[dateStr] || 0) + 1;
  });
  for (let i = 0; i < 7; i++) {
    const date = new Date(now.getFullYear(), now.getMonth(), now.getDate() - i);
    const dateStr = date.toLocaleDateString();
    if (!counts[dateStr]) {
      counts[dateStr] = 0;
    }
  }
  const labels = Object.keys(counts);
  const dataVal = Object.values(counts);
  var series = [
    {
      name: "",
      data: dataVal,
    },
  ];
  var options = {
    chart: {
      type: "area",
      height: 350,
      stacked: true,

      toolbar: {
        show: false,
      },
    },

    xaxis: {
      categories: labels,
    },

    dataLabels: {
      enabled: false,
    },
    yaxis: {
      labels: {
        formatter: function (value) {
          return Number.isInteger(value) ? value : "";
        },
      },
    },
  };
  return (
    <>
      <Card>
        <CardHeader title={"Alerts Overview"} />
        <CardContent>
          <ChartWrapper>
            <ReactApexChart
              options={options}
              series={series}
              type="area"
              height={350}
            />
          </ChartWrapper>
        </CardContent>
      </Card>
    </>
  );
};

export default AlertCharts;
