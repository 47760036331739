import React, { useState } from "react";
import styled from "styled-components/macro";
import { Power } from "react-feather";
import { useNavigate } from "react-router-dom";

import {
  Tooltip,
  Menu,
  MenuItem,
  IconButton as MuiIconButton,
} from "@mui/material";

import useUserStore from "../../services/userStore";
import {
  clearLocalStorageOnLogout,
} from "../../services/Helpers";
import { myLocalStorage } from "../StorageHelper";
import { useKeycloakStore } from "../../services/UserService";
import useAuth from "../../hooks/useAuth";
import { getClientId, logOut } from "../../services/Auth";

const IconButton = styled(MuiIconButton)`
  svg {
    width: 22px;
    height: 22px;
  }
`;

function NavbarUserDropdown() {
  const navigate = useNavigate();
  const { local_kc } = useKeycloakStore();
  const { signOut } = useAuth();

  const [anchorMenu, setAnchorMenu] = useState(null);

  const userData = useUserStore((state) => state.user);

  const email = userData.email || myLocalStorage.getItem("email");
  const logoutUri = myLocalStorage.getItem("logoutUri");
  const access_token = myLocalStorage.getItem("access_token");
  const refresh_token = myLocalStorage.getItem("refresh_token");

  const logOutAsInitialAdmin = () => {
    const realm = myLocalStorage.getItem("realm");
    signOut({
      realm,
      client_id: getClientId(realm),
      access_token,
      refresh_token,
    });

    navigate("/");
  };

  const logoutAsUser = () => {
    window.open(logoutUri, "_self");
    myLocalStorage.removeItem("logoutUri");
  };

  const logOutOnClientAndServer = () => {
    const isUserLoginedInitialAdmin = !logoutUri;

    try {
      if (isUserLoginedInitialAdmin) {
        logOutAsInitialAdmin();
      } else {
        logoutAsUser();
      }
    } catch (error) {
      local_kc && local_kc.doLogout();
      console.error(error);
    } finally {
      clearLocalStorageOnLogout();
    }
  };

  const toggleMenu = (event) => {
    setAnchorMenu(event.currentTarget);
  };

  const closeMenu = () => {
    setAnchorMenu(null);
  };
  const navigateToAccount = () => {
    navigate("/account");
    closeMenu();
  };

  const handleSignOut = async () => {
    try {
      await logOut(email);

      await logOutOnClientAndServer();
    } catch (error) {
      logOutOnClientAndServer();
      console.error(error);
    }
  };

  return (
    <React.Fragment>
      <Tooltip title="Account">
        <IconButton
          aria-owns={Boolean(anchorMenu) ? "menu-appbar" : undefined}
          aria-haspopup="true"
          onClick={toggleMenu}
          color="inherit"
          size="large"
        >
          <Power />
        </IconButton>
      </Tooltip>
      <Menu
        id="menu-appbar"
        anchorEl={anchorMenu}
        open={Boolean(anchorMenu)}
        onClose={closeMenu}
      >
        <MenuItem onClick={navigateToAccount}>Account</MenuItem>
        <MenuItem onClick={handleSignOut}>Sign out</MenuItem>
      </Menu>
    </React.Fragment>
  );
}

export default NavbarUserDropdown;
