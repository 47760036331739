import React, { useEffect, useState } from "react";
import axios from "axios";

import { NGROK } from "../../../../APIs";
import {
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Paper,
} from "@mui/material";
import MyHeaderTableCell from "../../Components/MyHeaderTableCell";
import ComputerItem from "./ComputerItem";
import SearchField from "../../logs/components/SearchField";
import useGeneralSearch from "../../../../hooks/useGeneralSearch";
import { Box } from "@mui/system";
import RadioFilter from "./RadioFilter";

const Computers = ({ unitId, selectedTenant }) => {
  const [deviceList, setDeviceList] = useState([]);
  const [filterValue, setFilterValue] = useState("Show All");

  const handleFilterResourceValue = (e) => {
    setFilterValue(e.target.value);
  };

  const {
    searchTerm,
    filteredData: devices,
    handleSearch,
  } = useGeneralSearch(deviceList);

  const filteredDevices = devices.filter((device) =>
    filterValue === "Show All"
      ? device
      : filterValue === "Agent Installed"
      ? device.hasAgent
      : !device.hasAgent
  );

  useEffect(() => {
    const fetchGroups = async () => {
      try {
        const response = await axios.get(
          `${NGROK}/api/organization-units/${unitId}/computers`
        );
        setDeviceList(response.data);
      } catch (error) {
        setDeviceList([]);
        console.error(error);
      }
    };

    if (unitId) fetchGroups();
  }, [setDeviceList, unitId]);

  return (
    <Stack spacing={2}>
      <Box
        display={"flex"}
        justifyContent={"space-between"}
        paddingTop={5}
        alignItems={"center"}
      >
        <RadioFilter
          handleFilterResourceValue={handleFilterResourceValue}
          filterValue={filterValue}
        />

        <SearchField
          sx={{ textAlign: "center" }}
          searchTerm={searchTerm}
          handleSearch={handleSearch}
        />
      </Box>

      <Typography variant="h6" color="initial" fontWeight={600} align="center">
        Number of elements: {filteredDevices?.length || "0"}
      </Typography>

      <TableContainer component={Paper}>
        <Table
          sx={{
            width: "100%",
            height: "fit-content",
            "& td, & th": {
              border: "1px solid #233044",
            },
          }}
        >
          <TableHead>
            <TableRow>
              <MyHeaderTableCell
                sx={{ fontSize: 18 }}
                align={"center"}
                className={"userName"}
              >
                Devices
              </MyHeaderTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredDevices?.map((device) => (
              <TableRow
                className="groupRow"
                key={`${device.id}${device.DistinguishedName}`}
              >
                <TableCell
                  component="td"
                  scope="row"
                  className="groupRowName userName"
                  title={device.DNSHostName}
                >
                  <ComputerItem
                    device={device}
                    selectedTenant={selectedTenant}
                  />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Stack>
  );
};

export default Computers;
