import React from "react";
import { List } from "@mui/material";
import DevicelistItem from "./DevicelistItem";

const Devicelist = ({ device }) => {
  return (
    <List sx={{ width: "100%", maxWidth: 360, bgcolor: "background.paper", padding: 0 }}>
      <DevicelistItem device={device} />
    </List>
  );
};

export default Devicelist;
