import React from "react";
import {
  Avatar,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Stack,
  Typography,
} from "@mui/material";
import { FaLinux, FaWindows, FaApple } from "react-icons/fa";

const DevicelistItem = ({ device }) => {
  const operatingSystems = {
    linux: "LINUX",
    windows: "WINDOWS",
    mac: "MAC",
  };

  const os = Object.keys(operatingSystems).find((key) =>
    device.OperatingSystem.toLowerCase().includes(key)
  );

  const osIcon = {
    linux: <FaLinux size={40} color={"#233044"} />,
    windows: <FaWindows size={35} color={"#233044"} />,
    mac: <FaApple size={40} color={"#233044"} />,
  };

  const getDeviceGroupName = (groups) => {
    if (!groups.length) return "-";

    const groupWithHighestRepScore = groups.reduce((prev, current) => {
      return prev && prev.reputationScore > current.reputationScore
        ? prev
        : current;
    }, groups[0]);

    return groupWithHighestRepScore.categoryName;
  };

  return (
    <ListItem>
      <ListItemAvatar>
        <Avatar sx={{ backgroundColor: "white" }}>{osIcon[os]}</Avatar>
      </ListItemAvatar>
      <Stack spacing={1}>
        <ListItemText
          primary={
            <Typography
              component="span"
              sx={{ fontSize: "15px", display: "inline" }}
            >
              {device.dNSHostName}
            </Typography>
          }
          secondary={
            <Stack spacing={1} component={"span"}>
              <Typography
                component="span"
                sx={{ fontSize: "14px", display: "inline" }}
                color="text.secondary"
              >
                {"OS: "}
                {device.OperatingSystem}
              </Typography>
              <Typography
                component="span"
                sx={{ fontSize: "14px", display: "inline" }}
                color="text.secondary"
              >
                {"Agent version: "}
                {device.agentVersion}
              </Typography>
              <Typography
                component="span"
                sx={{ fontSize: "14px", display: "inline" }}
                color="text.secondary"
              >
                {"Endpoint group: "}
                {getDeviceGroupName(device.categories)}
              </Typography>
            </Stack>
          }
        />
      </Stack>
    </ListItem>
  );
};

export default DevicelistItem;
