import React, { useState } from "react";
import axios from "axios";
import { NGROK } from "../../../APIs";

import Box from "@mui/material/Box";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";

import "../pages.scss";
import {
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import useUserStore from "../../../services/userStore";
import RadioAuthFilter from "../../components/RadioAuthFilter";
import MyHeaderTableCell from "../Components/MyHeaderTableCell";
import {
  getFilteredProfileResources,
  getInheritedResourceStatusValue,
  getResourceSelectValue,
} from "../../../services/Helpers";
import { useMutation, useQuery, useQueryClient } from "react-query";

const ProfileUrlControl = ({ selectedProfile, tenantName }) => {
  const queryClient = useQueryClient();
  const email = useUserStore((state) => state.user.email);
  const [filterUrlValue, setFilterUrlValue] = useState("ALL");
  const [searchUrlValue, setSearchUrlValue] = useState("");

  const findResource = (e) => {
    setTimeout(() => {
      setSearchUrlValue(e.target.value);
    }, 1500);
  };

  const handleFilterResourceValue = (e) => {
    setFilterUrlValue(e.target.value);
  };

  const fetchUrlControlList = async (os, profileId) => {
    try {
      return await axios.get(
        `${NGROK}/api/${os}/profiles/${profileId}/url-control`
      );
    } catch (error) {
      console.error(error);
    }
  };

  const changeUrlStatus = async ({ url, groupStatus }) => {
    try {
      return await axios.put(`${NGROK}/api/profiles/url-control/update`, {
        email,
        profileId: selectedProfile.id,
        urlId: url.urlId,
        groupStatus,
        tenantName,
      });
    } catch (error) {
      console.error(error);
    }
  };

  const updateStatusMutation = useMutation({
    mutationFn: changeUrlStatus,

    onSuccess: ({ data: newUrl }) => {
      queryClient.setQueryData(
        ["profileUrlControl", selectedProfile.os, selectedProfile.id],
        (oldQueryData) => {
          return {
            ...oldQueryData,
            data: {
              ...oldQueryData.data,
              content: oldQueryData?.data?.content?.map((oldUrl) =>
                oldUrl.id === newUrl.id ? newUrl : oldUrl
              ),
            },
          };
        }
      );
    },
  });

  const urlQueryResponse = useQuery({
    queryKey: ["profileUrlControl", selectedProfile.os, selectedProfile.id],
    queryFn: () => fetchUrlControlList(selectedProfile.os, selectedProfile.id),
    enabled: !!selectedProfile,
  });

  const urlControlList = urlQueryResponse
    ? urlQueryResponse.data?.data?.content
    : [];

  const filteredUrls = getFilteredProfileResources(
    searchUrlValue,
    filterUrlValue,
    urlControlList
  );

  return (
    <>
      <Stack spacing={2}>
        <Box
          display={"flex"}
          justifyContent={"space-between"}
          width={"100%"}
          sx={{ padding: "15px" }}
        >
          <RadioAuthFilter
            filterResourceValue={filterUrlValue}
            filterName={"Filter by privilege"}
            handleFilterResourceValue={handleFilterResourceValue}
          />
          {urlControlList?.length > 10 ? (
            <TextField
              sx={{ width: 250 }}
              label="Search..."
              id="searchForGroup"
              onChange={findResource}
            />
          ) : null}
        </Box>
        <Typography
          variant="h6"
          color="initial"
          fontWeight={600}
          align="center"
        >
          Number of elements: {filteredUrls?.length || "0"}
        </Typography>

        <TableContainer component={Paper}>
          <Table
            sx={{
              width: "100%",
              height: "fit-content",
              "& td, & th": {
                border: "1px solid #233044",
              },
            }}
          >
            <TableHead>
              <TableRow>
                <MyHeaderTableCell align={"center"} className={"userName"}>
                  URL
                </MyHeaderTableCell>
                <MyHeaderTableCell align={"center"} className={"userName"}>
                  Trust level
                </MyHeaderTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <>
                {filteredUrls?.map((url) => (
                  <TableRow
                    className="groupRow"
                    key={`${url.id}${url.urlName}${url.urlId}`}
                  >
                    <TableCell
                      component="td"
                      scope="row"
                      className="groupRowName userName"
                      title={url.urlName}
                    >
                      {url.urlName}
                    </TableCell>
                    <TableCell>
                      <Box className="profilePrivilegeLevelTableCell">
                        {" "}
                        <Select
                          sx={{ minWidth: "100px" }}
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={getResourceSelectValue(url)}
                          size="small"
                          onChange={(event) =>
                            updateStatusMutation.mutate({
                              url,
                              groupStatus: event.target.value,
                            })
                          }
                        >
                          <MenuItem value={"ENABLED"}>ALLOWED</MenuItem>
                          <MenuItem value={"DISABLED"}>DENIED</MenuItem>
                          <MenuItem value={"DYNAMIC"}>TIMED</MenuItem>
                          {url.trustLevel ? (
                            <MenuItem value={"INHERITED"}>
                              INHERITED {getInheritedResourceStatusValue(url)}
                            </MenuItem>
                          ) : null}
                        </Select>
                      </Box>
                    </TableCell>
                  </TableRow>
                ))}
              </>
            </TableBody>
          </Table>
        </TableContainer>
      </Stack>
    </>
  );
};

export default ProfileUrlControl;
