import create from "zustand";

const useNotificationStore = create((set) => ({
  adminNotifications: [],
  setAdminNotifications: (notifications) => {
    set(() => ({
      adminNotifications: notifications,
    }));
  },

  userNotifications: [],
  setUserNotifications: (notifications) => {
    set(() => ({
      userNotifications: notifications,
    }));
  },
}));

export default useNotificationStore;
