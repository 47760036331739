import React from "react";

import { Box, Tab, Typography } from "@mui/material";
import { TabContext, TabList } from "@mui/lab";
import { myLocalStorage } from "../../../../components/StorageHelper";

const OperationSystemTab = ({ windowsContent, linuxContent, macContent, osValue, setOsValue }) => {

  const handleChange = (event, newValue) => {
    setOsValue(newValue);
    myLocalStorage.setItem("selectedOS", newValue);
  };

  return (
    <Box sx={{ width: "100%", typography: "body1" }}>
      <TabContext value={osValue}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }} display={"flex"} justifyContent={"center"}>
          <TabList onChange={handleChange} aria-label="lab API tabs example">
            <Tab label="WINDOWS" value={"WINDOWS"} />
            <Tab label="LINUX" value={"LINUX"} />
            <Tab label="MAC" value={"MACOS"} />
          </TabList>
        </Box>
        <CustomTabPanel value={osValue} index={"WINDOWS"}>{windowsContent}</CustomTabPanel>
        <CustomTabPanel value={osValue} index={"LINUX"}>{linuxContent}</CustomTabPanel>
        <CustomTabPanel value={osValue} index={"MACOS"}>{macContent}</CustomTabPanel>
      </TabContext>
    </Box>
  );
};

const CustomTabPanel = ({ children, value, index, ...other }) => {
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography component={"span"}>{children}</Typography>
        </Box>
      )}
    </div>
  );
};

export default OperationSystemTab;
