import React, { useEffect, useState } from "react";
import axios from "axios";
import moment from "moment";
import { NGROK } from "../../../APIs";

import {
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Typography,
  Stack,
  Button,
} from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import useGeneralSearch from "../../../hooks/useGeneralSearch";
import SearchField from "./components/SearchField";
import CustomDatePicker from "./components/CustomDatePicker";
import useDateFilter from "../../../hooks/useDateFilter";
import { useLocation } from "react-router-dom";
import { myLocalStorage } from "../../../components/StorageHelper";
import { handleGeneratePDF } from "./components/helpers";

const DevieSpecificLogs = () => {
  const location = useLocation();
  const locationState = location?.state;
  const selectedTenant =
    locationState?.selectedTenant || myLocalStorage.getItem("latestTenant");
  const selectedDevice =
    locationState?.activeComputer || myLocalStorage.getItem("activeComputer");

  const [showCount, setShowCount] = useState(50);

  const [auditLogs, setAuditLogs] = useState([]);
  const [loading, setLoading] = useState(true);

  const forbiddenSearchFields = ["bucket", "tenantName", "_measurement"];
  const { searchTerm, filteredData, handleSearch } = useGeneralSearch(
    auditLogs,
    null,
    forbiddenSearchFields
  );

  const {
    dateRange,
    handleChangeDateRange,
    filteredData: logs,
  } = useDateFilter(filteredData, "time");

  useEffect(() => {
    const controller = new AbortController();
    const fetchAuditLogs = async () => {
      const bucket = "ondevice-auditlog";

      try {
        const response = await axios.get(
          `${NGROK}/api/influxdb/influxdblogsdata?bucketName=${bucket}`
        );

        const tenantLogs =
          response?.data?.filter(
            (log) => log.tenantName === selectedTenant?.tenantName
          ) || [];

        setAuditLogs(tenantLogs);
        setLoading(false);
      } catch (error) {
        console.error(error, "error fetchAuditLogs ");
        setLoading(false);
      }
    };

    fetchAuditLogs();

    const interval = setInterval(() => {
      fetchAuditLogs();
    }, 5000);

    return () => {
      controller.abort();
      clearInterval(interval);
    };
  }, []);

  const filterLogs = (logs, tenantName, deviceName = "") => {
    const filteredLogs = logs?.filter((log) =>
      !deviceName
        ? log.tenantName === tenantName
        : log.tenantName === tenantName && log.computerName === deviceName
    );

    const getTimestamp = (obj) => obj.timestamp || obj._time;
    const sortByDate = (a, b) => {
      const timestampA = moment(getTimestamp(a)).valueOf();
      const timestampB = moment(getTimestamp(b)).valueOf();
      return timestampB - timestampA;
    };
    return filteredLogs.sort(sortByDate);
  };

  const deviceAuditLogs = selectedTenant
    ? filterLogs(logs, selectedTenant.tenantName, selectedDevice?.dNSHostName)
    : [];

  if (loading) return <CircularProgress />;

  return (
    <Stack spacing={4}>
      <Stack direction={"row"} spacing={2}>
        <CustomDatePicker
          dateRange={dateRange}
          handleChangeDateRange={handleChangeDateRange}
        />
        <SearchField searchTerm={searchTerm} handleSearch={handleSearch} />
        <Button
          variant="outlined"
          onClick={() =>
            handleGeneratePDF(dateRange, deviceAuditLogs, searchTerm)
          }
        >
          Download Report
        </Button>
      </Stack>

      {deviceAuditLogs.length ? (
        <TableContainer
          component={Paper}
          sx={{
            overflowX: "auto",
          }}
        >
          <Table
            size="small"
            aria-label="a dense table"
            sx={{
              "& td, & th": {
                border: "1px solid #233044",
              },
            }}
          >
            <TableHead
              sx={{
                backgroundColor: "#233044",
              }}
            >
              <TableRow>
                <TableCell
                  sx={{ color: "white", fontSize: "16px" }}
                  align="center"
                >
                  Time
                </TableCell>
                <TableCell
                  sx={{ color: "white", fontSize: "16px" }}
                  align="center"
                >
                  Computer User
                </TableCell>
                <TableCell
                  sx={{ color: "white", fontSize: "16px" }}
                  align="center"
                >
                  Computer Name
                </TableCell>
                <TableCell
                  sx={{ color: "white", fontSize: "16px" }}
                  align="center"
                >
                  Type
                </TableCell>
                <TableCell
                  sx={{ color: "white", fontSize: "16px" }}
                  align="center"
                >
                  Resource Name
                </TableCell>
                <TableCell
                  sx={{ color: "white", fontSize: "16px" }}
                  align="center"
                >
                  Action
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {deviceAuditLogs.slice(0, showCount).map((log) => (
                <TableRow key={`${log.timestamp}${log.message}${log._time}`}>
                  <TableCell sx={{ fontSize: "16px" }} align="center">
                    {moment(
                      log.timestamp !== undefined ? log.timestamp : log._time
                    ).format("DD.MM.YYYY HH:mm:ss")}
                  </TableCell>
                  <TableCell sx={{ fontSize: "16px" }} align="center">
                    {log.computerUserEmail}
                  </TableCell>
                  <TableCell sx={{ fontSize: "16px" }} align="center">
                    {log.computerName}
                  </TableCell>
                  <TableCell sx={{ fontSize: "16px" }} align="center">
                    {log.resourceType}
                  </TableCell>
                  <TableCell
                    sx={{
                      fontSize: "16px",
                      maxWidth: "300px",
                      whiteSpace: "normal",
                      wordWrap: "break-word",
                    }}
                    align="center"
                  >
                    {log.resourceName}
                  </TableCell>
                  <TableCell sx={{ fontSize: "16px" }} align="center">
                    {log.message}
                  </TableCell>
                </TableRow>
              ))}
              {deviceAuditLogs?.length > showCount ? (
                <TableRow
                  sx={{
                    border: 1,
                  }}
                >
                  <TableCell colSpan={7} align="center">
                    <Button
                      variant={"text"}
                      onClick={() =>
                        setShowCount((prevCount) => prevCount + 50)
                      }
                    >
                      Load more...
                    </Button>
                  </TableCell>
                </TableRow>
              ) : null}
            </TableBody>
          </Table>
        </TableContainer>
      ) : selectedTenant ? (
        <Typography fontSize={16} p={5}>
          There are no logs related to selected Tenant or Device
        </Typography>
      ) : (
        <Typography fontSize={16} p={5}>
          Select Tenant
        </Typography>
      )}
    </Stack>
  );
};

export default DevieSpecificLogs;
