import React, { useState, useEffect } from "react";
import axios from "axios";
import { NGROK } from "../../../APIs";
import {
  Button,
  MenuItem,
  Select,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
  Paper,
  Box,
  CircularProgress,
} from "@mui/material";
import RadioAuthFilter from "../../components/RadioAuthFilter";
import MyHeaderTableCell from "./MyHeaderTableCell";
import StyledTooltip from "../../components/StyledTooltip";
import { useMutation, useQuery, useQueryClient } from "react-query";
import useUserStore from "../../../services/userStore";
import {
  getFilteredProfileResources,
  getInheritedResourceStatusValue,
  getResourceSelectValue,
  sortObjectsAlphabetically,
} from "../../../services/Helpers";
import useDebouncedValue from "../../../hooks/useDebouncedValue";

const ProfilesAppsTable = ({
  selectedProfile,
  selectedPublisher,
  showCount,
  setShowCount,
  tenantName,
}) => {
  const queryClient = useQueryClient();
  const userEmail = useUserStore((state) => state.user.email);
  const [apps, setApps] = useState([]);
  const [filterAppsValue, setFilterAppsValue] = useState("ALL");
  const [appsSearchValues, setAppsSearchValues] = useState("");

  const debouncedSearchTerm = useDebouncedValue(appsSearchValues, 1000);

  const getApplications = async (
    selectedProfileOs,
    selectedProfileId,
    selectedPublisherId
  ) => {
    const appArr = [];

    const getUrl = (publisherId) =>
      `${NGROK}/api/${selectedProfileOs}/profiles/${selectedProfileId}/publishers/${publisherId}/apps`;

    const fetchData = async (url) => {
      const result = await axios.get(url);
      if (result.data) {
        appArr.push(result.data.content);
      }
    };

    if (selectedPublisher.friendlyName !== "Unsigned Apps") {
      await Promise.all(
        selectedPublisher.ids.map((publisherId) =>
          fetchData(getUrl(publisherId))
        )
      );
    } else {
      await fetchData(getUrl(selectedPublisherId));
    }
    const appsSortedById = appArr.flat().sort((a, b) => a.appId - b.appId);
    return appsSortedById;
  };

  const getAllApplications = async (selectedProfileId) => {
    try {
      const response = await axios.get(
        `${NGROK}/api/profiles/get-all-apps?profileId=${selectedProfileId}`
      );

      return response.data;
    } catch (error) {
      console.error(error);
    }
  };

  const changeApplicationStatus = async ({ application, groupStatus }) => {
    try {
      return await axios.put(`${NGROK}/api/profiles/apps/update`, {
        appId: application.appId,
        email: userEmail,
        fingerprintSha256: application.fingerprintSha256,
        groupStatus,
        profileId: application.profileId,
        tenantName,
      });
    } catch (error) {
      console.error(error);
    }
  };

  const findApplication = (value) => {
    setAppsSearchValues(value);
  };

  const handleFilterResourceValue = (e) => {
    setFilterAppsValue(e.target.value);
  };

  const allAppsParameteresAreReady =
    !!selectedProfile &&
    !!selectedPublisher &&
    !!(selectedPublisher.id === "allAppsPublisher");

  const profileAppsParameteresAreReady =
    !!selectedProfile &&
    !!selectedPublisher &&
    !!(selectedPublisher.id !== "allAppsPublisher");

  const { data: allApps, isLoading: allAppsAreLoading } = useQuery({
    queryKey: ["allProfilesApps", selectedProfile.id],
    queryFn: () => getAllApplications(selectedProfile.id),
    enabled: allAppsParameteresAreReady,
  });

  const { data: profileApps, isLoading: appsAreLoading } = useQuery({
    queryKey: [
      "profileApps",
      selectedProfile.os,
      selectedProfile.id,
      selectedPublisher.id,
    ],
    queryFn: () =>
      getApplications(
        selectedProfile.os,
        selectedProfile.id,
        selectedPublisher.id
      ),
    enabled: profileAppsParameteresAreReady,
  });

  const updateStatusMutation = useMutation({
    mutationFn: changeApplicationStatus,

    onSuccess: ({ data: newObj }) => {
      queryClient.setQueryData(
        ["allProfilesApps", selectedProfile.id],
        (oldQueryData) => {
          return oldQueryData?.map((item) =>
            item.appId === newObj.appId ? newObj : item
          );
        }
      );

      queryClient.setQueryData(
        [
          "profileApps",
          selectedProfile.os,
          selectedProfile.id,
          selectedPublisher.id,
        ],
        (oldQueryData) => {
          return oldQueryData?.map((item) =>
            item.appId === newObj.appId ? newObj : item
          );
        }
      );
    },
  });

  const unicApps = apps?.reduce((accumulator, current) => {
    if (!accumulator.find((app) => app.appPath === current.appPath)) {
      accumulator.push(current);
    }
    return accumulator;
  }, []);

  const filteredApplications = sortObjectsAlphabetically(
    getFilteredProfileResources(debouncedSearchTerm, filterAppsValue, unicApps),
    "appName"
  );

  useEffect(() => {
    if (allApps && selectedPublisher.id === "allAppsPublisher")
      setApps(allApps);
    else if (profileApps) setApps(profileApps);
    else setApps([]);
  }, [allApps, profileApps, selectedPublisher.id]);

  if (allAppsAreLoading || appsAreLoading) {
    return (
      <Box display={"flex"} p={5}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box width={"100%"}>
      {unicApps?.length ? (
        <Stack spacing={2}>
          <Box
            display={"flex"}
            justifyContent={"space-between"}
            width={"100%"}
            sx={{ padding: "15px" }}
          >
            <RadioAuthFilter
              filterResourceValue={filterAppsValue}
              filterName={"Filter by privilege"}
              handleFilterResourceValue={handleFilterResourceValue}
            />
            <TextField
              sx={{ width: 250 }}
              label="Search..."
              id="searchForApplication"
              value={appsSearchValues}
              onChange={(e) => findApplication(e.target.value)}
            />
          </Box>
          <Typography
            variant="h6"
            color="initial"
            fontWeight={600}
            align="center"
          >
            Number of elements: {filteredApplications?.length || "0"}
          </Typography>
          <TableContainer component={Paper}>
            <Table
              sx={{
                width: "100%",
                height: "fit-content",
                "& td, & th": {
                  border: "1px solid #233044",
                },
              }}
              size="large"
            >
              <TableHead>
                <TableRow>
                  <MyHeaderTableCell align={"center"} className={"userName"}>
                    Application
                  </MyHeaderTableCell>
                  <MyHeaderTableCell align={"center"} className={"userName"}>
                    Trust level
                  </MyHeaderTableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                {filteredApplications
                  .slice(0, showCount)
                  ?.map((application) => (
                    <TableRow className="groupRow" key={application.id}>
                      <StyledTooltip
                        arrow
                        title={
                          <>
                            <Typography color="inherit">
                              {`Path: ${application.appPath}`}
                            </Typography>
                            {/* <br />
                            <Typography color="inherit">
                              {`Size: ${application.size}`}
                            </Typography>
                            <br /> */}
                            {/* <Typography color="inherit">
                              {`Fingerprint: ${application.fingerprintSha256}`}
                            </Typography> */}
                          </>
                        }
                        placement="left"
                      >
                        <TableCell className="userName">
                          {application.appName}
                        </TableCell>
                      </StyledTooltip>
                      <TableCell>
                        <Box className="profilePrivilegeLevelTableCell">
                          <Select
                            sx={{ minWidth: "100px" }}
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={getResourceSelectValue(application)}
                            size="small"
                            onChange={(event) =>
                              updateStatusMutation.mutate({
                                application,
                                groupStatus: event.target.value,
                              })
                            }
                          >
                            <MenuItem value={"ENABLED"}>ALLOWED</MenuItem>
                            <MenuItem value={"DISABLED"}>DENIED</MenuItem>
                            <MenuItem value={"DYNAMIC"}>TIMED</MenuItem>
                            {application.trustLevel ? (
                              <MenuItem value={"INHERITED"}>
                                INHERITED{" "}
                                {getInheritedResourceStatusValue(application)}
                              </MenuItem>
                            ) : null}
                          </Select>
                        </Box>
                      </TableCell>
                    </TableRow>
                  ))}
                {unicApps?.length > showCount ? (
                  <TableRow sx={{ border: 1 }}>
                    <TableCell colSpan={2}>
                      <Button
                        variant={"text"}
                        onClick={() =>
                          setShowCount((prevCount) => prevCount + 50)
                        }
                      >
                        Load more...
                      </Button>
                    </TableCell>
                  </TableRow>
                ) : null}
              </TableBody>
            </Table>
          </TableContainer>
        </Stack>
      ) : null}
    </Box>
  );
};

export default ProfilesAppsTable;
