import React, { useEffect, useState } from "react";
import axios from "axios";

import {
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Paper,
} from "@mui/material";
import { myLocalStorage } from "../../../components/StorageHelper";
import TenantSelection from "../Components/TenantSelection";
import { NGROK } from "../../../APIs";
import MyHeaderTableCell from "../Components/MyHeaderTableCell";
import ComputerItem from "../OrganizationUnit/components/ComputerItem";
import useGeneralSearch from "../../../hooks/useGeneralSearch";
import SearchField from "../logs/components/SearchField";
import RadioFilter from "../OrganizationUnit/components/RadioFilter";
import { Box } from "@mui/system";

const OuComputes = () => {
  const tenant = myLocalStorage.getItem("latestTenant");

  const [selectedTenant, setSelectedTenant] = useState(tenant);
  const [deviceList, setDeviceList] = useState([]);
  const [filterValue, setFilterValue] = useState("Show All");

  const handleFilterResourceValue = (e) => {
    setFilterValue(e.target.value);
  };

  const {
    searchTerm,
    filteredData: devices,
    handleSearch,
  } = useGeneralSearch(deviceList);

  const filteredDevices = devices.filter((device) =>
    filterValue === "Show All"
      ? device
      : filterValue === "Agent Installed"
      ? device.hasAgent
      : !device.hasAgent
  );

  useEffect(() => {
    const fetchGroups = async () => {
      try {
        const response = await axios.get(
          `${NGROK}/api/${selectedTenant.tenantName}/ad-computers`
        );
        setDeviceList(response.data);
      } catch (error) {
        setDeviceList([]);
        console.error(error);
      }
    };

    if (selectedTenant) fetchGroups();
  }, [selectedTenant, setDeviceList]);

  return (
    <Stack spacing={4}>
      <Box
        display={"flex"}
        justifyContent={"space-between"}
        alignItems={"center"}
      >
        <TenantSelection
          selectedTenant={selectedTenant}
          setSelectedTenant={setSelectedTenant}
        />{" "}
        <RadioFilter
          handleFilterResourceValue={handleFilterResourceValue}
          filterValue={filterValue}
        />
        <SearchField searchTerm={searchTerm} handleSearch={handleSearch} />
      </Box>

      <Stack spacing={2}>
        <Typography
          variant="h6"
          color="initial"
          fontWeight={600}
          align="center"
        >
          Number of elements: {filteredDevices?.length || "0"}
        </Typography>

        <TableContainer component={Paper}>
          <Table
            sx={{
              width: "100%",
              height: "fit-content",
              "& td, & th": {
                border: "1px solid #233044",
              },
            }}
          >
            <TableHead>
              <TableRow>
                <MyHeaderTableCell
                  sx={{ fontSize: 18 }}
                  align={"center"}
                  className={"userName"}
                >
                  Devices
                </MyHeaderTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredDevices?.map((device) => (
                <TableRow
                  className="groupRow"
                  key={`${device.id}${device.DistinguishedName}`}
                >
                  <TableCell
                    component="td"
                    scope="row"
                    className="groupRowName userName"
                    title={device.DNSHostName}
                  >
                    <ComputerItem
                      device={device}
                      selectedTenant={selectedTenant}
                    />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Stack>
    </Stack>
  );
};

export default OuComputes;
