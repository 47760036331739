import axios from "axios";
import { NGROK } from "../APIs";

export const channel = new BroadcastChannel("logout");

export const logOut = async (email) => {
  channel.postMessage({ logoutMessage: "logout" });
  try {
    axios.get(`${NGROK}/api/logout?email=${email}`);
  } catch (error) {
    console.error(error);
  }
};

export const getClientId = (realm) => {
    const realmCLientId = {
        Whiteswan_Tenants: "otp-auth",
        Whiteswan_Tenants_Initial: "otp-auth-initial",
        Whiteswan_Tenants_Legacy: "otp-auth-legacy"
    }
    return realmCLientId[realm] || `otp-auth-${realm}`;

};
