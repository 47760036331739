import Keycloak from "keycloak-js";
import axios from "axios";
import create from "zustand";

import { myLocalStorage } from "../components/StorageHelper";
import useUserStore from "./userStore";
import { setInitialSessionTime } from "./Helpers";

import { NGROK } from "../APIs";
import { decodeKeycloakToken, sendTokenToBackend } from "../utils/tokenHelpers";
import { getClientId } from "./Auth";

let local_kc;

export const useKeycloakStore = create((set) => ({
  local_kc: null,
  setLocalKc: (kcInstance) => set({ local_kc: kcInstance }),
}));

const fetchUserData = async (email) => {
  const { getUserData } = useUserStore.getState();
  await getUserData(email);
};

const initKeycloak = async (realmName) => {
  const axiosInstance = axios.create();
  const response = await axiosInstance.get(`${NGROK}/api/keycloak-url`);
  const keycloakUrl = response.data;
  const clientId = getClientId(realmName)

  local_kc = new Keycloak({
    url: keycloakUrl,
    realm: realmName,
    clientId,
    scope: "openid",
  });

  local_kc.onAuthSuccess = () => {
    const email = local_kc.tokenParsed.email || myLocalStorage.getItem("email");
    const role = myLocalStorage.getItem("role");

    fetchUserData(email);

    const logoutUri = local_kc.createLogoutUrl({
      redirectUri: `${NGROK}`,
    });

    const saveKeycloakInstance = () => {
      useKeycloakStore.getState().setLocalKc(local_kc);
    };

    saveKeycloakInstance();

    setInitialSessionTime(30);

    const { exp } = decodeKeycloakToken(local_kc.token);
    sendTokenToBackend(realmName, email, role, local_kc.token, exp);

    myLocalStorage.setItem("refresh_token", local_kc.refreshToken);
    myLocalStorage.setItem("access_token", local_kc.token);
    myLocalStorage.setItem("email", local_kc.tokenParsed.email);
    myLocalStorage.setItem("logoutUri", logoutUri);

    myLocalStorage.removeItem("keycloakIsInitializing");
  };

  local_kc.onAuthError = () => {
    myLocalStorage.removeItem("keycloakIsInitializing");
  };

  try {
    const authenticated = await local_kc.init({
      onLoad: "check-sso",
      promiseType: "native",
      pkceMethod: "S256",
    });

    if (authenticated) {
      myLocalStorage.removeItem("keycloakIsInitializing");

    } else {
      myLocalStorage.removeItem("keycloakIsInitializing");

      myLocalStorage.setItem("realm", realmName);
    }
  } catch (error) {
    console.error("Keycloak initialization error:", error);
  }
};

const _kc = () => {
  return local_kc;
};

const doLogin = async (email) => {
  try {
    console.log("User is logged in!");

    if (local_kc)
      await local_kc.login({
        loginHint: email,
        redirectUri: `${NGROK}/loading`,
      });
  } catch (error) {
    console.error("Keycloak login error:", error);
  }
};

const doRegister = async (email) => {
  const isAdminRegistering =
    local_kc &&
    (local_kc.realm === "Whiteswan_Tenants" ||
      local_kc.realm === "Whiteswan_Tenants_Legacy");

  try {
    if (isAdminRegistering) {
      await local_kc.login({ loginHint: email, action: "register" });
    } else if (local_kc && local_kc.realm !== "Whiteswan_Tenants") {
      await local_kc.login({
        loginHint: email,
        action: "register",
        redirectUri: `${NGROK}/loading`,
      });
    }
  } catch (error) {
    console.error("Keycloak login error:", error);
  }
};

const doLogout = () => {
  local_kc.logout();
};
const getToken = () => local_kc?.token;
const isLoggedIn = () => !!local_kc?.token;

const getUsername = () => local_kc.tokenParsed?.preferred_username;
const hasRole = (roles) => roles.some((role) => local_kc.hasRealmRole(role));

const UserService = {
  doLogin,
  initKeycloak,
  doLogout,
  doRegister,
  isLoggedIn,
  getToken,
  getUsername,
  hasRole,
  _kc,
};
export default UserService;
