import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Container,
  Divider,
  LinearProgress,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Stack,
  Typography,
  linearProgressClasses,
} from "@mui/material";
import React, { useEffect, useState } from "react";

import PersonOutlineOutlinedIcon from "@mui/icons-material/PersonOutlineOutlined";

import { styled } from "@mui/material/styles";
const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 10,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor:
      theme.palette.grey[theme.palette.mode === "light" ? 200 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: theme.palette.mode === "light" ? "#1a90ff" : "#308fe8",
  },
}));
const RiskyUsers = ({ alertJson }) => {
  const [riskyUsersData, setRiskyUserData] = useState(null);
  const getRiskyUsers = (json) => {
    const groupedData = json.reduce((acc, obj) => {
      const { computerUserEmail } = obj;
      acc[computerUserEmail] = (acc[computerUserEmail] || 0) + 1;
      return acc;
    }, {});

    const sortedData = Object.entries(groupedData)
      .sort(([, countA], [, countB]) => countB - countA)
      .reduce((acc, [key, value]) => ({ ...acc, [key]: value }), {});

    const totalCount = Object.values(sortedData).reduce(
      (sum, count) => sum + count,
      0
    );
    const dataWithPercentage = Object.entries(sortedData).map(
      ([key, value]) => ({
        user: key,
        count: value,
        percentage: ((value / totalCount) * 100).toFixed(2),
      })
    );
    setRiskyUserData(dataWithPercentage);
  };

  useEffect(() => {
    getRiskyUsers(alertJson);
  }, [alertJson]);
  return (
    <Card>
      <CardHeader title={"Risky Users"} />
      <CardContent>
        <div
          style={{
            height: 400,
          }}
        >
          <List sx={{ width: "100%", bgcolor: "background.paper" }}>
            {riskyUsersData !== null && riskyUsersData.length > 0 && (
              <>
                {riskyUsersData.map((value, index) => (
                  <div key={index}>
                    <Box
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignContent: "center",
                        alignItems: "center",
                        width: "100%",
                      }}
                    >
                      <ListItem sx={{ p: 5 }}>
                        <ListItemIcon>
                          <PersonOutlineOutlinedIcon />
                        </ListItemIcon>

                        <ListItemText>
                          <Typography variant="body1" fontWeight="bold">
                            {value.user}
                          </Typography>
                        </ListItemText>
                      </ListItem>
                      <Box
                        maxWidth={100}
                        sx={{
                          width: "100%",
                        }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                          }}
                          spacing={2}
                        >
                          <Stack spacing={2} sx={{ flex: 1 }}>
                            <BorderLinearProgress
                              variant="determinate"
                              value={parseInt(value.percentage)}
                            />
                          </Stack>
                          <Typography
                            variant="body2"
                            color="textSecondary"
                            sx={{
                              marginLeft: 2,
                            }}
                          >
                            {value.percentage}%
                          </Typography>
                        </Box>
                      </Box>
                    </Box>

                    {index < riskyUsersData.length - 1 && <Divider />}
                  </div>
                ))}
              </>
            )}
          </List>
          {riskyUsersData === null ||
            (riskyUsersData.length === 0 && (
              <>
                <Box
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignContent: "center",
                    alignItems: "center",
                    width: "100%",
                    height: "352px",
                  }}
                >
                  <Typography
                    variant="body2"
                    color="textSecondary"
                    sx={{
                      marginLeft: 2,
                    }}
                  >
                    No risky users available
                  </Typography>
                </Box>
              </>
            ))}
        </div>
      </CardContent>
    </Card>
  );
};

export default RiskyUsers;
