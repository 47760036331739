import React, { useEffect, useState } from "react";

import axios from "axios";

import {
  CircularProgress,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
  Box,
} from "@mui/material";
import ".//../pages.scss";
import Paper from "@mui/material/Paper";
import ApplicationsControl from "./ApplicationsControl";
import DataAccess from "./DataAccess";
import PrivilegeManagement from "./PrivilegeManagement";
import useUserStore from "../../../services/userStore";
import { myLocalStorage } from "../../../components/StorageHelper";
import { NGROK } from "../../../APIs";
import { useLocation } from "react-router-dom";
import URLcontrol from "./URLcontrol";
import MyHeaderTableCell from "../Components/MyHeaderTableCell";

const EndpointsResources = () => {
  const location = useLocation();
  const locationState = location?.state;

  const [selectedResourceId, setSelectedResourcesId] = useState(
    myLocalStorage.getItem("selectedEndpointsResourceId") || 2
  );
  const [usersList, setUsersList] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const [userSearchValues, setUserSearchValues] = useState("");

  const userData = useUserStore((state) => state.user);

  const [showCount, setShowCount] = useState(50);

  const [groupsSearchValues, setGroupsSearchValues] = useState("");
  const [foldersSearchValues, setFoldersSearchValues] = useState("");

  const selectedTenant =
    locationState?.selectedTenant || myLocalStorage.getItem("latestTenant");
  const activeComputer =
    locationState?.activeComputer || myLocalStorage.getItem("activeComputer");

  const getUsers = (tenantName, computerId) => {
    setUserSearchValues("");

    if (userData.role === "TENANT_ADMIN" || userData.role === "SYS_ADMIN") {
      axios
        .get(`${NGROK}/api/${tenantName}/computer-users/${computerId}`)
        .then((res) => {
          if (res.data) {
            setUsersList(res.data.content);
          }
        });
    } else if (userData.role === "TENANT_USER") {
      axios
        .get(
          `${NGROK}/api/${tenantName}/computer-users/${computerId}/users?email=${userData.email}`
        )
        .then((res) => {
          if (res.data) {
            setUsersList(res.data);
          }
        });
    }
  };

  useEffect(() => {
    if (selectedTenant && activeComputer)
      getUsers(selectedTenant?.tenantName, activeComputer?.id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userData]);

  useEffect(() => {
    if (usersList.length === 1) return setSelectedUser(usersList[0]);

    if (usersList?.length) {
      const latestUser = myLocalStorage.getItem("latestUser");

      const user = usersList.find((user) => user.id === latestUser?.id);

      if (user) setSelectedUser(user);
      else {
        setSelectedUser(usersList[0]);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [usersList]);

  useEffect(() => {
    myLocalStorage.setItem("selectedEndpointsResourceId", selectedResourceId);
  }, [selectedResourceId]);

  useEffect(() => {
    if (selectedUser) myLocalStorage.setItem("latestUser", selectedUser);
  }, [selectedUser]);

  return (
    <section className="domainTab">
      <Resources
        setFoldersSearchValues={setFoldersSearchValues}
        setGroupsSearchValues={setGroupsSearchValues}
        selectedResourceId={selectedResourceId}
        setSelectedResourcesId={setSelectedResourcesId}
      />
      <Stack direction={"row"} spacing={3}>
        {usersList?.length &&
        selectedUser &&
        userData.role !== "TENANT_USER" ? (
          <UsersTab
            users={usersList}
            selectedUserId={selectedUser.id}
            setSelectedUser={setSelectedUser}
            setUserSearchValues={setUserSearchValues}
            userSearchValues={userSearchValues}
          />
        ) : null}
        {selectedResourceId === 1 && selectedUser ? (
          <ApplicationsControl
            userData={userData}
            tenantName={selectedTenant?.tenantName}
            showCount={showCount}
            setShowCount={setShowCount}
            selectedUser={selectedUser}
          />
        ) : null}
        {selectedResourceId === 2 && selectedUser ? (
          <DataAccess
            userData={userData}
            tenantName={selectedTenant?.tenantName}
            showCount={showCount}
            setShowCount={setShowCount}
            selectedUser={selectedUser}
            foldersSearchValues={foldersSearchValues}
            setFoldersSearchValues={setFoldersSearchValues}
          />
        ) : null}
        {selectedResourceId === 3 && selectedUser ? (
          <PrivilegeManagement
            userData={userData}
            tenantName={selectedTenant?.tenantName}
            selectedUser={selectedUser}
            showCount={showCount}
            setShowCount={setShowCount}
            setGroupsSearchValues={setGroupsSearchValues}
            groupsSearchValues={groupsSearchValues}
          />
        ) : null}
        {selectedResourceId === 4 && selectedUser ? (
          <URLcontrol
            selectedUser={selectedUser}
            tenantName={selectedTenant?.tenantName}
            computer={activeComputer}
          />
        ) : null}
      </Stack>
    </section>
  );
};

const Resources = ({
  selectedResourceId,
  setSelectedResourcesId,
  setGroupsSearchValues,
  setFoldersSearchValues,
}) => {
  const resources = [
    {
      id: 1,
      title: "Application Control",
    },
    {
      id: 2,
      title: "Data Access",
    },
    {
      id: 3,
      title: "Privilege Management",
    },
    {
      id: 4,
      title: "URL control",
    },
  ];

  useEffect(() => {
    setFoldersSearchValues("");
    setGroupsSearchValues("");
  }, [selectedResourceId, setFoldersSearchValues, setGroupsSearchValues]);

  return (
    <section>
      <TableContainer style={{ height: "fit-content" }} component={Paper}>
        <Table
          sx={{
            width: "100%",
            border: "1px solid #233044",
          }}
          size="large"
        >
          <TableHead>
            <TableRow>
              <MyHeaderTableCell align={"center"} className={"userName"}>
                Resources
              </MyHeaderTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {resources.map((resource) => (
              <TableRow
                className={
                  resource.id === selectedResourceId
                    ? "userRow activeUserRow"
                    : "userRow"
                }
                key={resource.id}
                sx={{ "&:last-child td, &:last-child th": { border: 1 } }}
                onClick={() => {
                  setSelectedResourcesId(resource.id);
                }}
              >
                <TableCell
                  component="td"
                  scope="row"
                  className="userRowName userName"
                  title={resource.title}
                >
                  {resource.title}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </section>
  );
};

const UsersTab = ({
  users,
  selectedUserId,
  setSelectedUser,
  userSearchValues,
  setUserSearchValues,
}) => {
  const findUser = (e) => {
    setTimeout(() => {
      setUserSearchValues(e.target.value);
    }, 1500);
  };

  const filteredUsers = users.filter(
    (user) =>
      userSearchValues === "" ||
      user.cn.toLowerCase().includes(userSearchValues.toLowerCase())
  );

  return (
    <section>
      {users?.length ? (
        <TableContainer
          style={{ height: "fit-content", maxWidth: 400, minWidth: 200 }}
          component={Paper}
        >
          <Table
            sx={{
              width: "100%",
              border: "1px solid #233044",
            }}
            size="large"
          >
            <TableHead>
              <TableRow>
                <TableCell
                  align="center"
                  className="userName"
                  sx={{ color: "white", backgroundColor: "#233044" }}
                >
                  Users
                </TableCell>
              </TableRow>
              {users.length > 10 ? (
                <TableRow>
                  <TableCell>
                    <TextField
                      fullWidth
                      sx={{ minWidth: "200px" }}
                      label="Find user ..."
                      id="searchForUser"
                      onChange={findUser}
                    />
                  </TableCell>
                </TableRow>
              ) : null}
            </TableHead>
            <TableBody>
              {filteredUsers.map((user) => (
                <TableRow
                  sx={{ "&:last-child td, &:last-child th": { border: 1 } }}
                  className={
                    user.id === selectedUserId
                      ? "userRow activeUserRow"
                      : "userRow"
                  }
                  key={user.cn}
                >
                  <TableCell
                    component="td"
                    scope="row"
                    className="userRowName userName"
                    title={user.cn}
                    onClick={() => {
                      setSelectedUser(user);
                    }}
                  >
                    <Typography className="userRowName userName">
                      {user.cn}
                    </Typography>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      ) : users === undefined ? (
        <Box display={"flex"} p={5}>
          <CircularProgress />
        </Box>
      ) : (
        <p style={{ fontSize: "20px", fontWeight: "500" }}>
          This computer doesn't have any users.
        </p>
      )}
    </section>
  );
};

export default EndpointsResources;
