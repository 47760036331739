import React, { useEffect, useState } from "react";
import axios from "axios";

import {
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Stack,
  Button,
  Checkbox,
  CircularProgress,
} from "@mui/material";
import { NGROK } from "../../../APIs";
import { myLocalStorage } from "../../../components/StorageHelper";
import { useLocation } from "react-router-dom";
import useGeneralSearch from "../../../hooks/useGeneralSearch";
import UserSearch from "./components/UserSearch";
import { Box } from "@mui/system";
import TenantSelection from "../Components/TenantSelection";

const RegisteredUsers = () => {
  const location = useLocation();
  const locationState = location?.state;

  const tenant =
    locationState?.tenant || myLocalStorage.getItem("latestTenant");

  const [selectedTenant, setSelectedTenant] = useState(tenant);
  const realmName = tenant?.tenantName;

  const [users, setUsers] = useState([]);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [realm, setRealm] = useState({
    passkeyRealm: "",
    legacyRealm: "",
  });
  const [loading, setLoading] = useState(true);

  const {
    searchTerm,
    filteredData: usersList,
    handleSearch,
  } = useGeneralSearch(users);

  const fetchUsers = async ({ passkeyRealm, legacyRealm }) => {
    try {
      const passkeyRealmResponse = passkeyRealm
        ? await axios.get(`${NGROK}/api/keycloak/${passkeyRealm}/users`)
        : null;

      const legacyRealmResponse = legacyRealm
        ? await axios.get(`${NGROK}/api/keycloak/${legacyRealm}/users`)
        : null;

      const result = [];

      if (passkeyRealmResponse && passkeyRealmResponse.data.length) {
        result.push(...passkeyRealmResponse.data);
      }

      if (legacyRealmResponse && legacyRealmResponse.data.length) {
        result.push(...legacyRealmResponse.data);
      }

      const unicUsers = result?.reduce((accumulator, current) => {
        if (!accumulator.find((user) => user.email === current.email)) {
          accumulator.push(current);
        }
        return accumulator;
      }, []);

      setLoading(false);
      setUsers(unicUsers);
    } catch (error) {
      setLoading(false);
      console.error(error);
    }
  };

  const handleUserSelect = (user) => {
    const userAlreadySelected = selectedUsers.some(
      (selectedUser) => JSON.stringify(selectedUser) === JSON.stringify(user)
    );

    if (userAlreadySelected) {
      const updatedUsers = selectedUsers.filter(
        (selectedUser) => JSON.stringify(selectedUser) !== JSON.stringify(user)
      );
      setSelectedUsers(updatedUsers);
    } else {
      setSelectedUsers((prev) => [...prev, user]);
    }
  };

  const selectAllUsers = () => {
    const allUsersAlreadySelected = selectedUsers.length === usersList.length;

    if (allUsersAlreadySelected) setSelectedUsers([]);
    else setSelectedUsers(usersList);
  };

  const checkIfUserSelected = (user) =>
    selectedUsers.some(
      (selectedUser) => JSON.stringify(user) === JSON.stringify(selectedUser)
    );

  const isEveryUserSelected =
    selectedUsers.length === usersList.length ? true : false;

  const deleteSelectedUsers = async ({ passkeyRealm, legacyRealm }) => {
    const usersToDelete = selectedUsers.map((user) => user.email);
    try {
      const response = await axios.put(
        `${NGROK}/api/keycloak/${passkeyRealm}/users`,
        usersToDelete
      );

      const response2 = await axios.put(
        `${NGROK}/api/keycloak/${legacyRealm}/users`,
        usersToDelete
      );

      if (response?.data === true || response2?.data === true) {
        setSelectedUsers([]);
        fetchUsers(realm);
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    const setSecondRealm = (realm) => {
      if (!realm) return;

      if (realm.endsWith(".local")) {
        const legacyRealm = realm.replace(".local", "-legacy.local");
        setRealm({
          passkeyRealm: realm,
          legacyRealm,
        });
      } else if (realm.endsWith("-legacy.local")) {
        const passkeyRealm = realm.replace("-legacy.local", ".local");
        setRealm({
          passkeyRealm,
          legacyRealm: realm,
        });
      } else if (realm.endsWith("_Legacy")) {
        const passkeyRealm = realm.replace("_Legacy", "");
        setRealm({
          passkeyRealm,
          legacyRealm: realm,
        });
      } else if (!realm.endsWith("_Legacy")) {
        const legacyRealm = `${realm}_Legacy`;
        setRealm({
          passkeyRealm: realm,
          legacyRealm,
        });
      }
    };

    setSecondRealm(realmName);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const hasEmptyValues = Object.values(realm).includes("");
    if (!hasEmptyValues) fetchUsers(realm);
  }, [realm]);

  if (loading) {
    return (
      <Box display={"flex"} p={5}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <>
      <Stack sx={{ paddingBottom: 5 }} direction={"row"} spacing={3}>
        <TenantSelection
          selectedTenant={selectedTenant}
          setSelectedTenant={setSelectedTenant}
        />
        <UserSearch searchTerm={searchTerm} handleSearch={handleSearch} />
        <Button
          disabled={!selectedUsers?.length}
          onClick={() => deleteSelectedUsers(realm)}
          variant="outlined"
          color="error"
        >
          Delete user
        </Button>
      </Stack>

      <TableContainer component={Paper}>
        <Table
          aria-label="simple table"
          sx={{
            "& td, & th": {
              border: "1px solid #233044",
            },
          }}
        >
          <TableHead
            sx={{
              backgroundColor: "#233044",
            }}
          >
            <TableRow>
              <TableCell
                sx={{ color: "white", fontSize: "16px" }}
                align="center"
              >
                <Checkbox
                  checked={isEveryUserSelected}
                  onChange={selectAllUsers}
                  sx={{
                    color: "white",
                    "&.Mui-checked": {
                      color: "white",
                    },
                  }}
                  size="large"
                  inputProps={{ "aria-label": "controlled" }}
                />
              </TableCell>
              <TableCell
                sx={{ color: "white", fontSize: "16px" }}
                align="center"
              >
                User Name
              </TableCell>
              <TableCell
                sx={{ color: "white", fontSize: "16px" }}
                align="center"
              >
                Email
              </TableCell>
              <TableCell
                sx={{ color: "white", fontSize: "16px" }}
                align="center"
              >
                First Name
              </TableCell>
              <TableCell
                colSpan={1}
                sx={{ color: "white", fontSize: "16px" }}
                align="center"
              >
                Last Name
              </TableCell>
              <TableCell
                colSpan={1}
                sx={{ color: "white", fontSize: "16px" }}
                align="center"
              >
                Status
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {usersList?.map((user) => (
              <TableRow
                key={user.email}
                sx={{
                  border: 1,
                }}
              >
                <TableCell sx={{ fontSize: "16px" }} align="center">
                  {" "}
                  <Checkbox
                    checked={checkIfUserSelected(user)}
                    onChange={() => handleUserSelect(user)}
                    size="medium"
                    inputProps={{ "aria-label": "controlled" }}
                  />
                </TableCell>
                <TableCell sx={{ fontSize: "16px" }}>{user.username}</TableCell>
                <TableCell sx={{ fontSize: "16px" }} align="center">
                  {user.email}
                </TableCell>
                <TableCell sx={{ fontSize: "16px" }} align="center">
                  {user.firstName}
                </TableCell>
                <TableCell sx={{ fontSize: "16px" }} align="center">
                  {" "}
                  {user.lastName}
                </TableCell>
                <TableCell sx={{ fontSize: "16px" }} align="center">
                  {" "}
                  {user.lastAccess ? "Online" : "Offline"}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default RegisteredUsers;
