import React, { useState } from "react";
import {
  Grid,
  Link,
  Box,
  TextField,
  Button,
  Alert,
  CircularProgress,
  Typography,
  Stack,
} from "@mui/material";
import * as Yup from "yup";
import { useFormik } from "formik";
import axios from "axios";
import { NGROK } from "../../../../APIs";
import UserService from "../../../../services/UserService";
import { myLocalStorage } from "../../../../components/StorageHelper";

const SignInEmailCheck = ({
  setRealmName,
  setTenantName,
  email,
  setEmail,
  togleActiveForm,
}) => {
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [loading, setLoading] = useState(false);
  const [emailErrorMessage, setEmailErrorMessage] = useState("");

  const SignupSchema = Yup.object().shape({
    email: Yup.string().required("Required"),
  });

  const formik = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema: SignupSchema,
    onSubmit: (values) => {
      console.log(JSON.stringify(values, null, 2));
    },
  });

  const getTenantsInfo = async () => {
    const axiosInstance = axios.create();

    try {
      return await axiosInstance.get(
        `${NGROK}/api/get-tenants?email=${email.toLowerCase()}`
      );
    } catch (error) {
      setTenantName("");
      console.error(error);
    }
  };

  const handleChange = (e) => {
    setEmail(e.target.value);
    formik.handleChange(e);

    setIsSubmitted(false);
    setEmailErrorMessage("");
  };

  const checkIfUserExist = async (email) => {
    const tenants = await getTenantsInfo(email);

    if (!tenants || !tenants.data?.length) {
      setTenantName("");
      return false;
    } else if (tenants?.data?.length) {
      setTenantName(tenants?.data[0].tenantName);
      myLocalStorage.setItem("role", tenants?.data[0].role);
      return tenants?.data[0];
    }
  };

  const keycloakInitAndLogin = async (realm, email) => {
    try {
      setLoading(true);
      myLocalStorage.setItem("email", email);
      myLocalStorage.setItem("realm", realm);

      UserService.initKeycloak(realm);
      setTimeout(() => {
        UserService.doLogin(email);
      }, 2000);
    } catch (error) {
      setLoading(false);

      console.log(error);
    }
  };

  const signInAsSysAdmin = async (user) => {
    if (!formik.errors.email && email) {
      if (user) {
        myLocalStorage.setItem("email", email);

        setTenantName(user.realmName);
        setRealmName(user.legacyRealm.realmName);

        togleActiveForm();
      }
    }
    setIsSubmitted(true);
  };

  const submitSignIn = async (e) => {
    e.preventDefault();
    setIsSubmitted(true);
    formik.handleSubmit();

    if (!formik.errors.email && email) {
      const user = await checkIfUserExist(email);

      if (!user) {
        setEmailErrorMessage(<Typography>This user doesn't exist.</Typography>);
        return;
      } else {
        if (user && user.role === "SYS_ADMIN") {
          signInAsSysAdmin(user);
          return;
        }

        if (user.realm?.keycloakAccountExists) {
          const realm =
            user.role === "TENANT_USER"
              ? user.realm.realmName
              : "Whiteswan_Tenants";
          keycloakInitAndLogin(realm, email);
        } else if (user.legacyRealm?.keycloakAccountExists) {
          const realm =
            user.role === "TENANT_USER"
              ? user.legacyRealm.realmName
              : "Whiteswan_Tenants_Legacy";
          keycloakInitAndLogin(realm, email);
        } else {
          setEmailErrorMessage(
            <Typography>This user doesn't exist.</Typography>
          );
          return;
        }
      }
    }
    setIsSubmitted(true);
  };

  if (loading)
    return (
      <Box display={"flex"} p={5}>
        <CircularProgress />
      </Box>
    );

  return (
    <Box
      component="form"
      onSubmit={(e) => submitSignIn(e)}
      noValidate
      sx={{ mt: 3, width: "100%" }}
    >
      <TextField
        required
        fullWidth
        id="email"
        label="Email Address"
        name="email"
        autoComplete="email"
        onChange={(e) => handleChange(e)}
      />
      {formik.errors.email && isSubmitted ? (
        <Alert severity="error">{formik.errors.email}</Alert>
      ) : null}
      {emailErrorMessage && isSubmitted ? (
        <Alert sx={{ marginTop: 1 }} severity="error">
          {emailErrorMessage}
        </Alert>
      ) : null}
      <Stack spacing={2} sx={{ mt: 3, mb: 2 }}>
        <Button type="submit" fullWidth variant="contained">
          Sign In
        </Button>
      </Stack>

      <Grid container justifyContent="flex-end">
        <Grid item>
          <Link href="/signUp" variant="body2">
            Don't have an account? Sign up!
          </Link>
        </Grid>
      </Grid>
    </Box>
  );
};

export default SignInEmailCheck;
