import axios from "axios";

const axiosInstance = axios.create();

axios.interceptors.request.use((config) => {
    // Do something before request is sent
    return config;
  }, function (error) {
    // Do something with request error
    return Promise.reject(error);
  });

export default axiosInstance;
