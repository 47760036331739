import { Navigate, useLocation } from "react-router-dom";
import { myLocalStorage } from "../StorageHelper";
import { useKeycloakStore } from "../../services/UserService";

const PrivateRoute = ({ element }) => {
  const { local_kc } = useKeycloakStore();
  const location = useLocation();

  const access_token = myLocalStorage.getItem("access_token");
  const isAuthenticated = local_kc?.authenticated;
  const keycloakIsInitializing = myLocalStorage.getItem(
    "keycloakIsInitializing"
  );

  return isAuthenticated || access_token || keycloakIsInitializing  ? (
    element
  ) : (
    <Navigate to="/signIn" state={{ from: location }} replace />
  );
};

export default PrivateRoute;
