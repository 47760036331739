import React, { useState } from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import {
  Alert,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  Stack,
  Typography,
} from "@mui/material";
import * as Yup from "yup";
import { useFormik } from "formik";
import useAuth from "../../../../hooks/useAuth";
import axios from "axios";
import { NGROK } from "../../../../APIs";
import UserService, {
  useKeycloakStore,
} from "../../../../services/UserService";
import { useNavigate } from "react-router-dom";
import { myLocalStorage } from "../../../../components/StorageHelper";

import FormLogo from "./FormLogo";
import { decodeKeycloakToken } from "../../../../utils/tokenHelpers";
import useUserStore from "../../../../services/userStore";

const SignUpForm = () => {
  const { signUp } = useAuth();
  const navigate = useNavigate();

  const [isSubmitted, setIsSubmitted] = useState(false);
  const [emailErrorMessage, setEmailErrorMessage] = useState("");
  const [tenantNameErrorMessage, setTenantNameErrorMessage] = useState("");

  const [tenantName, setTenantName] = useState("");
  const [loading, setLoading] = useState(false);
  const [serviceTermsAgreed, setServiceTermsAgreed] = useState(false);

  const { local_kc } = useKeycloakStore();
  const { getUserData: fetchUserData } = useUserStore((state) => state);

  const isTenantNameFormActive =
    local_kc &&
    (local_kc.realm === "Whiteswan_Tenants" ||
      local_kc.realm === "Whiteswan_Tenants_Legacy");

  const SignupSchema = Yup.object().shape({
    email: Yup.string().required("Required"),
    tenantName: Yup.string()
      .min(5, "Too Short!")
      .max(50, "Too Long!")
      .required("Required"),
  });

  const formik = useFormik({
    initialValues: {
      fullName: "",
      tenantName,
      email: "",
      password: "",
    },
    validationSchema: SignupSchema,
    onSubmit: (values) => {
      console.log(JSON.stringify(values, null, 2));
    },
  });
  const { tenantName: formikTenantName, email } = formik.values;

  const getTenantsInfo = async (email) => {
    const axiosInstance = axios.create();

    try {
      const tenants = await axiosInstance.get(
        `${NGROK}/api/get-tenants?email=${email.toLowerCase()}`
      );
      return tenants;
    } catch (error) {
      setTenantName("");
      console.error(error);
    }
  };

  const checkIfUserExist = async (email) => {
    const tenants = await getTenantsInfo(email);

    if (!tenants || !tenants.data?.length) {
      setTenantName("");
      return false;
    } else if (tenants?.data?.length) {
      myLocalStorage.setItem("role", tenants?.data[0].role);

      setTenantName(tenants?.data[0].tenantName);
      return tenants?.data[0];
    }
  };

  const handleChange = (e) => {
    formik.handleChange(e);
    if (isSubmitted) setIsSubmitted(false);
    setEmailErrorMessage("");
    setTenantNameErrorMessage("");
  };

  const keycloakInitAndRegister = async (realm) => {
    try {
      setLoading(true);
      myLocalStorage.setItem("realm", realm);
      UserService.initKeycloak(realm);

      setTimeout(() => {
        UserService.doRegister(email);
      }, 2000);
    } catch (error) {
      console.log(error);
    }
  };

  const startRegisterViaPasskey = async () => {
    if (!formik.errors.email) {
      setIsSubmitted(true);
      formik.handleSubmit();
      const user = await checkIfUserExist(email);
      if (
        user?.legacyRealm?.realmName === "Whiteswan_Tenants_Initial" ||
        user?.realm?.keycloakAccountExists
      ) {
        setEmailErrorMessage(
          <Typography>User with this email already exists.</Typography>
        );
        return;
      }

      if (!user) {
        myLocalStorage.setItem("role", "TENANT_ADMIN");
        myLocalStorage.setItem("email", email);

        keycloakInitAndRegister("Whiteswan_Tenants");
      } else if (user && !user.realm.keycloakAccountExists) {
        myLocalStorage.setItem("role", "TENANT_USER");
        myLocalStorage.setItem("email", email);

        keycloakInitAndRegister(user.tenantName);
      }
    }
  };

  const startRegisterViaPassword = async () => {
    if (!formik.errors.email) {
      setIsSubmitted(true);
      formik.handleSubmit();
      const user = await checkIfUserExist(email);
      if (
        user?.legacyRealm?.realmName === "Whiteswan_Tenants_Initial" ||
        user?.legacyRealm?.keycloakAccountExists
      ) {
        setEmailErrorMessage(
          <Typography>User with this email already exists.</Typography>
        );
        return;
      }

      if (!user) {
        myLocalStorage.setItem("role", "TENANT_ADMIN");
        myLocalStorage.setItem("email", email);

        keycloakInitAndRegister("Whiteswan_Tenants_Legacy");
      } else if (user && !user.legacyRealm.keycloakAccountExists) {
        myLocalStorage.setItem("role", "TENANT_USER");
        myLocalStorage.setItem("email", email);

        keycloakInitAndRegister(`${user.legacyRealm.realmName}`);
      }
    }
  };

  const createTenant = async (local_kc) => {
    setLoading(true);

    const { data: tenantIsUnic } = await axios.get(
      `${NGROK}/api/test-connection?tenantName=${formikTenantName}`
    );
    if (tenantIsUnic && formikTenantName && !formik.errors.tenantName) {
      const {
        email: userEmail,
        family_name,
        given_name,
      } = decodeKeycloakToken(local_kc.token);

      try {
        const response = await signUp(
          formikTenantName,
          userEmail,
          `${family_name} ${given_name}`
        );
        fetchUserData(userEmail);

        if (response) {
          navigate("/loading");
        } else {
          setLoading(false);
        }
      } catch (error) {
        setTenantNameErrorMessage("Something went wrong during test connection.")
        console.error(error, "sign up error");
      }
    }
  };

  const handleCheckbox = () => {
    setServiceTermsAgreed(!serviceTermsAgreed);
  };

  if (loading)
    return (
      <Box display={"flex"} p={5}>
        <CircularProgress />
      </Box>
    );

  return (
    <>
      <Typography component="h1" variant="h5" sx={{ padding: 2 }}>
        {isTenantNameFormActive ? "Setup Tenant Name" : "Sign up"}
      </Typography>
      <FormLogo />

      <Box
        component="form"
        noValidate
        // onSubmit={(e) => {
        //   submitForm(e);
        // }}
        sx={{ mt: 3, width: "100%" }}
      >
        <Grid container spacing={2}>
          {isTenantNameFormActive ? (
            <Grid item xs={12}>
              <TextField
                required
                fullWidth
                id="tenantName"
                label="Tenant Name"
                name="tenantName"
                autoComplete="family-name"
                onChange={(e) => handleChange(e)}
              />
              {formik.errors.tenantName && isSubmitted ? (
                <Alert severity="error">{formik.errors.tenantName}</Alert>
              ) : null}
              {tenantNameErrorMessage && isSubmitted ? (
                <Alert severity="error">{tenantNameErrorMessage}</Alert>
              ) : null}
            </Grid>
          ) : (
            <Grid item xs={12}>
              <TextField
                required
                fullWidth
                id="email"
                label="Email Address"
                name="email"
                autoFocus
                autoComplete="email"
                onChange={(e) => handleChange(e)}
              />
              {formik.errors.email && isSubmitted ? (
                <Alert sx={{ marginTop: 1 }} severity="error">
                  {formik.errors.email}
                </Alert>
              ) : null}
              {!formik.errors?.email && emailErrorMessage ? (
                <Alert sx={{ marginTop: 1 }} severity="error">
                  {emailErrorMessage}
                </Alert>
              ) : null}
            </Grid>
          )}
        </Grid>
        {isTenantNameFormActive ? (
          <Button
            onClick={() => createTenant(local_kc)}
            fullWidth
            variant="contained"
            sx={{ mt: 1, mb: 2 }}
          >
            Setup Tenant Name
          </Button>
        ) : (
          <Stack spacing={1} sx={{ mt: 3, mb: 2 }}>
            <Button
              onClick={() => startRegisterViaPasskey()}
              disabled={!serviceTermsAgreed || !email}
              fullWidth
              variant="contained"
            >
              Sign Up Passwordless
            </Button>
            <Typography align="center" variant="subtitle1" fontWeight={600}>
              OR
            </Typography>
            <Button
              onClick={() => startRegisterViaPassword()}
              disabled={!serviceTermsAgreed || !email}
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              Sign Up with Password and OTP
            </Button>
          </Stack>
        )}
        <Grid container justifyContent="space-between">
          {!isTenantNameFormActive ? (
            <Grid item>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={serviceTermsAgreed}
                    onChange={handleCheckbox}
                    color="primary"
                  />
                }
                label={
                  <Typography>
                    I agree to the{" "}
                    <Link href="/terms_of_service">Terms and Conditions</Link>
                  </Typography>
                }
                labelPlacement="end"
              />
            </Grid>
          ) : null}

          <Grid item>
            <Link href="/signIn" variant="body1">
              Already have an account? Sign in
            </Link>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default SignUpForm;
