import {
  MenuItem,
  Select,
  Card as MuiCard,
  CardContent,
  CardHeader,
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TableFooter,
  TablePagination,
  Box,
  Typography,
  CircularProgress,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { spacing } from "@mui/system";
import styled, { withTheme } from "styled-components/macro";
import { makeStyles } from "@mui/styles";
import ComputerOutlinedIcon from "@mui/icons-material/ComputerOutlined";
import { formatDateTime } from "../../../GenericMethods";
import AccessTimeOutlinedIcon from "@mui/icons-material/AccessTimeOutlined";

const Card = styled(MuiCard)(spacing);
const ChartWrapper = styled.div`
  height: 520px;
`;
const useStyles = makeStyles({
  root: {
    maxHeight: 480,
    overflowY: "scroll",
    "&::-webkit-scrollbar": {
      width: "0.4em",
    },
    "&::-webkit-scrollbar-track": {
      boxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "#888",
      borderRadius: "10px",
    },
  },
  row: {
    "& > td": {
      padding: "30px",
    },
  },
});

const StyledTableRow = ({ children, ...rest }) => {
  const classes = useStyles();
  return (
    <TableRow className={classes.row} {...rest}>
      {children}
    </TableRow>
  );
};
const RecentLogin = ({ data }) => {
  const [loginCount, setLoginCount] = useState(5);
  const [recentloginData, setRecentLoginData] = useState(null);
  const classes = useStyles();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [loading, setLoading] = useState(true);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const getLoginActivity = () => {
    if (data) {
      let filteredData = data.filter((loginName) =>
        ["User Reconnect", "User Login", "User Logoff"].includes(
          loginName.resourceName
        )
      );

      filteredData.sort((a, b) => new Date(b.time) - new Date(a.time));
      setRecentLoginData(filteredData);
      setLoading(false);
    }
  };

  const extractVal = (str) => {
    const containsLoggedOff = str.includes("logged off");
    if (containsLoggedOff) {
      return "logoff";
    } else {
      return "login";
    }
  };

  useEffect(() => {
    getLoginActivity(5);
  }, [data, loginCount]);
  return (
    <>
      <Card>
        <CardHeader title="Recent Login" />
        <CardContent>
          <ChartWrapper>
            {loading ? (
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "100%",
                }}
              >
                <CircularProgress />
              </div>
            ) : (
              <Paper sx={{ width: "100%", overflow: "hidden" }}>
                <TableContainer
                  sx={{ maxHeight: 400 }}
                  className={classes.root}
                >
                  <Table
                    stickyHeader
                    aria-label="sticky table"
                    className={classes.table}
                  >
                    <TableHead>
                      <TableRow>
                        <TableCell
                          sx={{
                            backgroundColor: "#6592ad",
                            color: "#fff",
                          }}
                        >
                          Computer Name
                        </TableCell>

                        <TableCell
                          sx={{
                            backgroundColor: "#6592ad",
                            color: "#fff",
                          }}
                          align="center"
                        >
                          Message
                        </TableCell>
                        <TableCell
                          sx={{
                            backgroundColor: "#6592ad",
                            color: "#fff",
                          }}
                          align="center"
                        >
                          Status
                        </TableCell>
                        <TableCell
                          sx={{
                            backgroundColor: "#6592ad",
                            color: "#fff",
                          }}
                          align="center"
                        >
                          Updated on
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {recentloginData !== null &&
                        recentloginData
                          .slice(
                            page * rowsPerPage,
                            page * rowsPerPage + rowsPerPage
                          )
                          .map((dataVal, index) => (
                            <StyledTableRow
                              key={dataVal.id}
                              style={{
                                backgroundColor:
                                  index % 2 === 0
                                    ? "white"
                                    : "rgb(245 245 248)",
                              }}
                            >
                              <TableCell>
                                <Box
                                  sx={{
                                    display: "flex",
                                    alignItems: "center",
                                  }}
                                >
                                  <ComputerOutlinedIcon />
                                  <Typography
                                    variant="body1"
                                    sx={{
                                      marginLeft: 2,
                                    }}
                                  >
                                    {dataVal.computerName}
                                  </Typography>
                                </Box>
                              </TableCell>

                              <TableCell align="left" width={"60%"}>
                                {dataVal.message}
                              </TableCell>
                              <TableCell align="left">
                                <div
                                  style={{
                                    padding: 5,
                                    width: "70px",
                                    borderRadius: 5,
                                    display: "flex",
                                    alignItems: "center",
                                    textAlign: "center",
                                    justifyContent: "space-around",
                                    color: "#fff",
                                    backgroundColor:
                                      extractVal(dataVal.message) === "logoff"
                                        ? "rgb(245,12,12)"
                                        : "rgb(44 156 94)",
                                  }}
                                >
                                  {extractVal(dataVal.message)}
                                </div>
                              </TableCell>

                              <TableCell align="right" width={"60%"}>
                                <Box
                                  sx={{
                                    display: "flex",
                                    alignItems: "center",
                                  }}
                                >
                                  <AccessTimeOutlinedIcon />
                                  <div
                                    style={{
                                      marginLeft: 5,
                                    }}
                                  >
                                    {formatDateTime(dataVal.time)}
                                  </div>
                                </Box>
                              </TableCell>
                            </StyledTableRow>
                          ))}
                    </TableBody>
                  </Table>
                </TableContainer>
                <TablePagination
                  rowsPerPageOptions={[10, 25, 100]}
                  component="div"
                  count={recentloginData !== null ? recentloginData.length : 5}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              </Paper>
            )}
          </ChartWrapper>
        </CardContent>
      </Card>
    </>
  );
};

export default RecentLogin;
