import React, { useEffect } from "react";

import {
  Table,
  Paper,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  Typography,
  TableBody,
  Button,
} from "@mui/material";
import { Box } from "@mui/system";
import DeviceGroupDialog from "./DeviceGroupDialog";
import { deleteDeviceGroup, fetchDeviceGroups, fetchDevices } from "../api";
import { toLowerCaseExceptFirst } from "../../../../services/Helpers";

const DeviceGroupsTable = ({
  os,
  setSelectedGroup,
  selectedGroup,
  deviceList,
  setDeviceList,
  tenantName,
  profilesList,
  setDeviceGroups,
  deviceGroups,
}) => {
  const handleDeleteDeviceGroup = async (groupId) => {
    await deleteDeviceGroup(groupId);

    const newDeviceGroups = await fetchDeviceGroups(tenantName, os);
    const newDeviceList = await fetchDevices(tenantName, os);

    setDeviceList(newDeviceList);
    setDeviceGroups(newDeviceGroups);
  };

  useEffect(() => {
    const fetchData = async () => {
      const data = await fetchDeviceGroups(tenantName, os);

      setDeviceGroups(data);
    };

    fetchData();
  }, [tenantName, os, setDeviceGroups]);

  useEffect(() => {
    if (deviceGroups?.length && !selectedGroup)
      setSelectedGroup(deviceGroups[0]);
  }, [deviceGroups, selectedGroup, setSelectedGroup]);

  const sortedGroups = deviceGroups.sort(
    (a, b) => b.reputationScore - a.reputationScore
  );

  return (
    <TableContainer
      sx={{
        height: "fit-content",
        width: "49%",
        marginTop: 2.5,
      }}
      component={Paper}
    >
      <Table
        aria-label="simple table"
        sx={{
          height: "fit-content",
          "& td, & th": {
            border: "1px solid #233044",
          },
        }}
      >
        <TableHead sx={{ backgroundColor: "#233044" }}>
          <TableRow>
            <TableCell
              align="center"
              className="userName"
              sx={{ color: "white" }}
            >
              <Typography component={"span"} fontSize={17} fontWeight={600}>
                Priority
              </Typography>
            </TableCell>
            <TableCell
              align="center"
              className="userName"
              sx={{ color: "white", maxWidth: 120 }}
            >
              <Typography component={"span"} fontSize={17} fontWeight={600}>
                Profile
              </Typography>
            </TableCell>
            <TableCell
              align="center"
              className="userName"
              sx={{ color: "white" }}
            >
              <Typography component={"span"} fontSize={17} fontWeight={600}>
                Device group
              </Typography>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {sortedGroups?.length
            ? sortedGroups?.map((group) => (
                <TableRow
                  key={group.uuid}
                  className={
                    group.name === selectedGroup?.name
                      ? "userRow activeUserRow"
                      : "userRow"
                  }
                  onClick={() => {
                    setSelectedGroup(group);
                  }}
                >
                  <TableCell align="center">
                    <Typography component={"span"} fontSize={16}>
                      {group.reputationScore}
                    </Typography>
                  </TableCell>
                  <TableCell sx={{ maxWidth: 140 }}>
                    <Typography
                      component={"span"}
                      fontSize={16}
                      sx={{ wordWrap: "break-word" }}
                    >
                      {group.profileName}
                    </Typography>
                  </TableCell>

                  <TableCell
                    sx={{
                      maxWidth: 330,
                      minWidth: 290,
                      wordWrap: "break-word",
                    }}
                    component="td"
                    className="userName"
                  >
                    <Box display={"flex"} justifyContent={"space-between"} alignItems={"center"}>
                      <Typography
                        component={"span"}
                        className="userName"
                        fontSize={16}
                        maxWidth={"65%"}
                        sx={{ wordWrap: "break-word" }}
                      >
                        {toLowerCaseExceptFirst(group.name)}
                      </Typography>
                      <Box
                        width={150}
                        display={"flex"}
                        justifyContent={"space-between"}
                      >
                        <DeviceGroupDialog
                          profilesList={profilesList}
                          tenantName={tenantName}
                          deviceList={deviceList}
                          os={os}
                          selectedGroup={group}
                          setDeviceGroups={setDeviceGroups}
                          setDeviceList={setDeviceList}
                        />
                        <Button
                          onClick={() => handleDeleteDeviceGroup(group.uuid)}
                          variant="outlined"
                          color="error"
                        >
                          Delete
                        </Button>
                      </Box>
                    </Box>
                  </TableCell>
                </TableRow>
              ))
            : null}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default DeviceGroupsTable;
