import React, { useEffect, useState } from "react";
import Chart from "react-apexcharts";
import styled, { withTheme } from "styled-components/macro";
import {
  Card as MuiCard,
  CardContent,
  CardHeader,
  IconButton,
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  TablePagination,
  Paper,
  TableContainer,
  Box,
  Typography,
  Chip,
  Stack,
} from "@mui/material";
import { color, spacing } from "@mui/system";
import { MoreVertical } from "react-feather";
import { makeStyles } from "@mui/styles";
import ComputerOutlinedIcon from "@mui/icons-material/ComputerOutlined";
import PersonOutlineOutlinedIcon from "@mui/icons-material/PersonOutlineOutlined";
import { formatDateTime } from "../../../GenericMethods";
import AccessTimeOutlinedIcon from "@mui/icons-material/AccessTimeOutlined";

const Card = styled(MuiCard)(spacing);
const ChartWrapper = styled.div`
  height: 600px;
`;
const useStyles = makeStyles({
  root: {
    height: 600,
    overflow: "scroll",
    "&::-webkit-scrollbar": {
      width: "0.4em",
      height: "0.4em",
    },
    "&::-webkit-scrollbar-track": {
      boxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "#888",
      borderRadius: "10px",
    },
  },
  row: {
    "& > td": {
      padding: "20px",
    },
  },
});
const StyledTableRow = ({ children, ...rest }) => {
  const classes = useStyles();
  return (
    <TableRow className={classes.row} {...rest}>
      {children}
    </TableRow>
  );
};
const AlertsTable = ({ alertJson }) => {
  const classes = useStyles();
  const [jsonValue, setJsonValue] = useState(null);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  const jsonValueExtraxtor = (jsonData) => {
    const extractedData = jsonData.map((obj) => {
      const {
        computerUserEmail,
        computerName,
        resourceName,
        message,
        eventJson,
        time,
        sigma_results,
      } = obj;

      const { Level, Tags, Title } = JSON.parse(sigma_results)[0];
      return {
        computerUserEmail,
        computerName,
        resourceName,
        message,
        Level,
        Tags,
        Title,
        time,
      };
    });
    extractedData.sort((a, b) => new Date(b.time) - new Date(a.time));

    setJsonValue(extractedData);
  };

  useEffect(() => {
    jsonValueExtraxtor(alertJson);
  }, [alertJson]);

  return (
    <>
      {jsonValue !== null && (
        <Card>
          <CardHeader
            title="Recent Alerts"
            action={
              <IconButton aria-label="settings" size="large">
                <MoreVertical />
              </IconButton>
            }
          />
          <CardContent>
            <ChartWrapper>
              <Paper sx={{ width: "100%", overflow: "hidden" }}>
                <TableContainer
                  sx={{ maxHeight: 560 }}
                  className={classes.root}
                >
                  <Table
                    stickyHeader
                    aria-label="sticky table"
                    className={classes.table}
                  >
                    <TableHead>
                      <TableRow>
                        <TableCell
                          sx={{
                            backgroundColor: "#6592ad",
                            color: "#fff",
                          }}
                          align="center"
                        >
                          Level
                        </TableCell>
                        <TableCell
                          sx={{
                            backgroundColor: "#6592ad",
                            color: "#fff",
                          }}
                          align="center"
                        >
                          User
                        </TableCell>

                        <TableCell
                          sx={{
                            backgroundColor: "#6592ad",
                            color: "#fff",
                          }}
                          align="center"
                        >
                          Computer Name
                        </TableCell>
                        <TableCell
                          sx={{
                            backgroundColor: "#6592ad",
                            color: "#fff",
                          }}
                          align="center"
                        >
                          Process Name
                        </TableCell>
                        <TableCell
                          sx={{
                            backgroundColor: "#6592ad",
                            color: "#fff",
                          }}
                          align="left"
                        >
                          Message
                        </TableCell>
                        <TableCell
                          sx={{
                            backgroundColor: "#6592ad",
                            color: "#fff",
                          }}
                          align="center"
                        >
                          Tags
                        </TableCell>

                        <TableCell
                          sx={{
                            backgroundColor: "#6592ad",
                            color: "#fff",
                          }}
                          align="center"
                        >
                          Updated on
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {jsonValue !== null && jsonValue.length > 0 ? (
                        <>
                          {jsonValue
                            .slice(
                              page * rowsPerPage,
                              page * rowsPerPage + rowsPerPage
                            )
                            .map((dataVal, index) => (
                              <StyledTableRow
                                key={index}
                                style={{
                                  backgroundColor:
                                    index % 2 === 0
                                      ? "white"
                                      : "rgb(245 245 248)",
                                }}
                              >
                                <TableCell align="center">
                                  <div
                                    style={{
                                      padding: 5,
                                      width: "60px",
                                      borderRadius: 5,
                                      display: "flex",
                                      alignItems: "center",
                                      textAlign: "center",
                                      justifyContent: "space-around",
                                      color: "#fff",
                                      backgroundColor:
                                        dataVal.Level === "medium"
                                          ? "#FFC700"
                                          : dataVal.Level === "high"
                                          ? "#FB6D48"
                                          : "rgb(245,12,12)",
                                    }}
                                  >
                                    {dataVal.Level.charAt(0).toUpperCase() +
                                      dataVal.Level.slice(1)}
                                  </div>
                                </TableCell>
                                <TableCell>
                                  <Box
                                    sx={{
                                      display: "flex",
                                      alignItems: "center",
                                    }}
                                  >
                                    <PersonOutlineOutlinedIcon />
                                    <Typography
                                      variant="body1"
                                      sx={{
                                        marginLeft: 2,
                                      }}
                                    >
                                      {dataVal.computerUserEmail}
                                    </Typography>
                                  </Box>
                                </TableCell>

                                <TableCell align="left">
                                  <Box
                                    sx={{
                                      display: "flex",
                                      alignItems: "center",
                                    }}
                                  >
                                    <ComputerOutlinedIcon />
                                    <Typography
                                      variant="body1"
                                      sx={{
                                        marginLeft: 2,
                                      }}
                                    >
                                      {dataVal.computerName}
                                    </Typography>
                                  </Box>
                                </TableCell>
                                <TableCell align="left">
                                  {dataVal.resourceName}
                                </TableCell>
                                <TableCell align="left">
                                  {dataVal.message}
                                </TableCell>
                                <TableCell align="right">
                                  {dataVal.Tags.map((dataTags, index) => (
                                    <Stack
                                      key={index}
                                      direction="row"
                                      spacing={1}
                                      gap={2}
                                      marginBottom={5}
                                    >
                                      <Chip
                                        sx={{
                                          bgcolor: "#5da3f5",
                                          color: "white",
                                        }}
                                        label={dataTags}
                                        key={index}
                                      />
                                    </Stack>
                                  ))}
                                </TableCell>

                                <TableCell align="right" width={"10%"}>
                                  <Box
                                    sx={{
                                      display: "flex",
                                      alignItems: "center",
                                    }}
                                  >
                                    <AccessTimeOutlinedIcon />
                                    <div
                                      style={{
                                        marginLeft: 5,
                                      }}
                                    >
                                      {formatDateTime(dataVal.time)}
                                    </div>
                                  </Box>
                                </TableCell>
                              </StyledTableRow>
                            ))}
                        </>
                      ) : (
                        <>
                          <TableCell colSpan={10} style={{ border: "none" }}>
                            <Box
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                alignContent: "center",
                                alignItems: "center",
                                width: "100%",
                                height: "450px",
                              }}
                            >
                              <Typography
                                variant="body2"
                                color="textSecondary"
                                sx={{
                                  marginLeft: 2,
                                }}
                              >
                                No recent alerts available
                              </Typography>
                            </Box>
                          </TableCell>
                        </>
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
                {jsonValue !== null && jsonValue.length > 0 && (
                  <TablePagination
                    rowsPerPageOptions={[10, 25, 100]}
                    component="div"
                    count={jsonValue !== null ? jsonValue.length : 5}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                  />
                )}
              </Paper>
            </ChartWrapper>
          </CardContent>
        </Card>
      )}
    </>
  );
};

export default AlertsTable;
