import {
  Card as MuiCard,
  CardContent,
  CardHeader,
  Tabs,
  Tab,
  Box,
} from "@mui/material";
import { spacing } from "@mui/system";
import React, { useState } from "react";
import styled, { withTheme } from "styled-components/macro";
import { TabContext, TabPanel } from "@mui/lab";
import RiskyAlerts from "./RiskyAlerts";
import RiskyMachines from "./RiskyMachines";
import RiskyUsers from "./RiskyUsers";

const Card = styled(MuiCard)(spacing);
const ChartWrapper = styled.div`
  height: 378px;
`;
const AlertsAnalytics = ({ alertJson }) => {
  const [value, setValue] = useState("riskyAlerts");

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <>
      <TabContext value={value}>
        <Card>
          <CardHeader
            title={
              <Tabs
                value={value}
                onChange={handleChange}
                textColor="secondary"
                indicatorColor="secondary"
                aria-label="secondary tabs example"
              >
                <Tab value="riskyAlerts" label="Top Alerts" />
                <Tab value="riskyMachines" label="Top Risky Machines" />
                <Tab value="riskyUsers" label="Top Risky Users" />
              </Tabs>
            }
          />
          <CardContent>
            <ChartWrapper>
              <TabPanel value="riskyAlerts">
                <Box
                  sx={{
                    height: 378,
                    margin: 0,
                  }}
                >
                  <RiskyAlerts alertJson={alertJson} />
                </Box>
              </TabPanel>
              <TabPanel value="riskyMachines">
                <Box
                  sx={{
                    height: 378,
                    margin: 0,
                  }}
                >
                  <RiskyMachines alertJson={alertJson} />
                </Box>
              </TabPanel>
              <TabPanel value="riskyUsers">
                <Box
                  sx={{
                    height: 378,
                    margin: 0,
                  }}
                >
                  <RiskyUsers alertJson={alertJson} />
                </Box>
              </TabPanel>
            </ChartWrapper>
          </CardContent>
        </Card>
      </TabContext>
    </>
  );
};

export default AlertsAnalytics;
