import React, { useEffect, useState } from "react";
import axios from "axios";
import { NGROK } from "../../../APIs";

import Box from "@mui/material/Box";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";

import "../pages.scss";
import {
  Button,
  CircularProgress,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import RadioAuthFilter from "../../components/RadioAuthFilter";
import MyHeaderTableCell from "../Components/MyHeaderTableCell";
import {
  getFilteredProfileResources,
  getInheritedResourceStatusValue,
  getResourceSelectValue,
} from "../../../services/Helpers";
import useDebouncedValue from "../../../hooks/useDebouncedValue";

const ProfileDataAcces = ({
  userData,
  showCount,
  setShowCount,
  foldersSearchValues,
  setFoldersSearchValues,
  tenantName,
  selectedProfile,
}) => {
  const [filterFoldersValue, setFilterFoldersValue] = useState("ALL");
  const [folders, setFolders] = useState([]);

  const debouncedSearchTerm = useDebouncedValue(foldersSearchValues, 1000);

  const findFolder = (value) => {
    setFoldersSearchValues(value);
  };

  const changeFolderStatus = async (folder, groupStatus) => {
    const profileResource =
      groupStatus === "ENABLED"
        ? "ALLOWED"
        : groupStatus === "DISABLED"
        ? "BLOCKED"
        : groupStatus === "DYNAMIC"
        ? "DYNAMIC"
        : "INHERITED";
    setFolders(
      folders.map((el) =>
        el.id === folder.id ? { ...folder, profileResource } : el
      )
    );

    try {
      await axios.put(`${NGROK}/api/profiles/folders/update`, {
        email: userData.email,
        folderId: folder.folderId,
        groupStatus,
        profileId: folder.profileId,
        tenantName,
      });
    } catch (error) {
      setFolders(folders.map((el) => (el.id === folder.id ? folder : el)));
    }
  };

  const handleFilterResourceValue = (e) => {
    setFilterFoldersValue(e.target.value);
  };

  useEffect(() => {
    const getFolders = async (profile) => {
      setFoldersSearchValues("");
      setFolders(undefined);

      const response = await axios.get(
        `${NGROK}/api/${profile.os}/profiles/${profile.id}/folders`
      );
      if (response.data) {
        const foldersSortedById = response.data.content.sort(
          (a, b) => a.folderId - b.folderId
        );
        setFolders(foldersSortedById);
      }
    };
    getFolders(selectedProfile);
  }, [selectedProfile, setFoldersSearchValues]);

  const filteredFolders = getFilteredProfileResources(
    debouncedSearchTerm,
    filterFoldersValue,
    folders
  );

  console.log(filteredFolders, "filteredFolders");

  return (
    <>
      {folders && folders.length ? (
        <Stack spacing={2}>
          <Box
            display={"flex"}
            justifyContent={"space-between"}
            width={"100%"}
            sx={{ padding: "15px" }}
          >
            <RadioAuthFilter
              filterResourceValue={filterFoldersValue}
              filterName={"Filter by privilege"}
              handleFilterResourceValue={handleFilterResourceValue}
            />
            <TextField
              sx={{ width: 250 }}
              label="Search..."
              id="searchForGroup"
              value={foldersSearchValues}
              onChange={(e) => findFolder(e.target.value)}
            />
          </Box>
          <Typography
            variant="h6"
            color="initial"
            fontWeight={600}
            align="center"
          >
            Number of elements: {filteredFolders?.length || "0"}
          </Typography>

          <TableContainer component={Paper}>
            <Table
              sx={{
                width: "100%",
                height: "fit-content",
                "& td, & th": {
                  border: "1px solid #233044",
                },
              }}
            >
              <TableHead>
                <TableRow>
                  <MyHeaderTableCell align={"center"} className={"userName"}>
                    Folder
                  </MyHeaderTableCell>
                  <MyHeaderTableCell align={"center"} className={"userName"}>
                    Trust level
                  </MyHeaderTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <>
                  {filteredFolders?.slice(0, showCount).map((folder) => (
                    <TableRow
                      className="groupRow"
                      key={`${folder.id}${folder.folderName}${folder.folderId}`}
                    >
                      <TableCell
                        component="td"
                        scope="row"
                        className="groupRowName userName"
                        title={folder.path}
                      >
                        {folder.path}
                      </TableCell>
                      <TableCell>
                        <Box className="profilePrivilegeLevelTableCell">
                          <Select
                            sx={{ minWidth: "100px" }}
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={getResourceSelectValue(folder)}
                            size="small"
                            onChange={(event) =>
                              changeFolderStatus(folder, event.target.value)
                            }
                          >
                            <MenuItem value={"ENABLED"}>ALLOWED</MenuItem>
                            <MenuItem value={"DISABLED"}>DENIED</MenuItem>
                            <MenuItem value={"DYNAMIC"}>TIMED</MenuItem>
                            {folder.trustLevel ? (
                              <MenuItem value={"INHERITED"}>
                                INHERITED{" "}
                                {getInheritedResourceStatusValue(folder)}
                              </MenuItem>
                            ) : null}
                          </Select>
                        </Box>
                      </TableCell>
                    </TableRow>
                  ))}
                  {filteredFolders?.length > showCount ? (
                    <TableRow>
                      <TableCell colSpan={2}>
                        <Button
                          variant={"text"}
                          onClick={() =>
                            setShowCount((prevCount) => prevCount + 50)
                          }
                        >
                          Load more...
                        </Button>
                      </TableCell>
                    </TableRow>
                  ) : null}
                </>
              </TableBody>
            </Table>
          </TableContainer>
        </Stack>
      ) : folders === undefined ? (
        <Box display={"flex"} p={5}>
          <CircularProgress />
        </Box>
      ) : (
        <Box pl={5}>
          <p style={{ fontSize: "20px", fontWeight: "500" }}>
            There are no any folders.
          </p>
        </Box>
      )}
    </>
  );
};

export default ProfileDataAcces;
