import React, { useEffect, useState } from "react";
import axios from "axios";

import {
  Button,
  FormControlLabel,
  FormGroup,
  Paper,
  Step,
  StepContent,
  StepLabel,
  Stepper,
  Typography,
  Box,
  TextField,
  Stack,
  FormControl,
  FormLabel,
  RadioGroup,
  Radio,
  Autocomplete,
} from "@mui/material";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import moment from "moment/moment";

import useUserStore from "../../../../services/userStore";
import { NGROK } from "../../../../APIs";
import { DateRangePicker, LocalizationProvider } from "@mui/lab";
import LineChart from "../../../dashboards/Default/LineChart";
import { myLocalStorage } from "../../../../components/StorageHelper";
import { setLocalStorageTenant } from "../../../../services/Helpers";

const steps = [
  {
    label: "Select tenant",
    description: ``,
  },
  {
    label: "Date range",
    description: "",
  },
  {
    label: "Select report type",
    description: ``,
  },
];

const PrivilegeCreep = () => {
  const userEmail = useUserStore((state) => state.user.email);

  const [activeStep, setActiveStep] = useState(0);

  const [tenantsList, setTenantsList] = useState([]);
  const [selectedTenant, setSelectedTenant] = useState(
    myLocalStorage.getItem("latestTenant")
  );

  const [dateRange, setDateRange] = useState([null, null]);

  const [reportType, setReportType] = useState("LineChart");

  const [stepsCompleted, setStepsCompleted] = useState(false);

  const handleFinish = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setStepsCompleted(true);
  };

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    if (stepsCompleted) setStepsCompleted(false);
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setStepsCompleted(false);
    setActiveStep(0);
  };

  const handleChangeDateRange = (newValue) => {
    const parsedDates = newValue.map((el) => moment(el).format("YYYY-MM-DD"));
    setDateRange(parsedDates);
  };

  const handleReportType = (e) => {
    setReportType(e.target.value);
  };

  const monthRange = dateRange.map((dateStr) => moment(dateStr).month());

  useEffect(() => {
    const fetchTenantsData = async () => {
      const response = await axios.get(
        `${NGROK}/api/get-all-domains?email=${userEmail}`
      );
      setTenantsList(response.data);
    };

    fetchTenantsData();
  }, [userEmail]);

  useEffect(() => {
    if (tenantsList.length && !selectedTenant)
      setSelectedTenant(tenantsList[0]);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tenantsList]);

  return (
    <Box sx={{ maxWidth: 800 }}>
      <Stepper activeStep={activeStep} orientation="vertical">
        {steps.map((step, index) => (
          <Step key={step.label}>
            <StepLabel
              optional={
                index === 4 ? (
                  <Typography color={"black"} fontSize={15}>
                    Last step
                  </Typography>
                ) : index === 0 && selectedTenant ? (
                  <Typography color={"black"} fontSize={15}>
                    {selectedTenant.tenantName}
                  </Typography>
                ) : index === 1 &&
                  dateRange.every((el) => el && el !== "Invalid date") ? (
                  <Typography color={"black"} fontSize={15}>
                    {`${dateRange[0]} - ${dateRange[1]}`}
                  </Typography>
                ) : index === 2 && reportType ? (
                  <Typography color={"black"} fontSize={15}>
                    {reportType}
                  </Typography>
                ) : null
              }
            >
              {step.label}
            </StepLabel>
            <StepContent>
              <Typography>{step.description}</Typography>
              <FormGroup row>
                {activeStep === 0 && tenantsList.length ? (
                  <Autocomplete
                    disablePortal
                    id="combo-box-demo"
                    value={
                      selectedTenant
                        ? selectedTenant.tenantName
                        : tenantsList[0].tenantName
                    }
                    options={tenantsList.map((tenant) => tenant.tenantName)}
                    sx={{
                      width: 300,
                      marginTop: "15px",
                      backgroundColor: "white",
                    }}
                    renderInput={(params) => (
                      <TextField {...params} label="Tenant List" />
                    )}
                    onChange={(e, value) => {
                      if (!value) {
                        setSelectedTenant(null);
                      }
                      const tenant = tenantsList.find(
                        (tenant) => tenant.tenantName === value
                      );
                      if (tenant !== undefined) {
                        setSelectedTenant(tenant);
                        setLocalStorageTenant(tenant)
                      }
                    }}
                  />
                ) : activeStep === 1 ? (
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DateRangePicker
                      startText="Check-in"
                      endText="Check-out"
                      value={dateRange}
                      onChange={handleChangeDateRange}
                      renderInput={(startProps, endProps) => (
                        <Stack direction={"row"} spacing={3}>
                          <TextField {...startProps} />
                          <TextField {...endProps} />
                        </Stack>
                      )}
                    />
                  </LocalizationProvider>
                ) : (
                  <FormControl>
                    <FormLabel id="demo-radio-buttons-group-label">
                      Report type
                    </FormLabel>
                    <RadioGroup
                      aria-labelledby="demo-radio-buttons-group-label"
                      value={reportType}
                      onChange={(e) => handleReportType(e)}
                      name="radio-buttons-group"
                    >
                      <FormControlLabel
                        value="LineChart"
                        control={<Radio />}
                        label="LineChart"
                      />
                      {/* <FormControlLabel
                        value="BarChart"
                        control={<Radio />}
                        label="BarChart"
                      />
                      <FormControlLabel
                        value="Table"
                        control={<Radio />}
                        label="Table"
                      /> */}
                    </RadioGroup>
                  </FormControl>
                )}
              </FormGroup>
              <Box sx={{ mb: 2 }}>
                <div>
                  <Button
                    disabled={
                      (activeStep === 0 && !selectedTenant) ||
                      (activeStep === 1 &&
                        !dateRange.every(
                          (el) => el && el !== "Invalid date"
                        )) ||
                      (activeStep === 2 && !reportType)
                    }
                    variant="contained"
                    onClick={
                      index === steps.length - 1 ? handleFinish : handleNext
                    }
                    sx={{ mt: 1, mr: 1 }}
                  >
                    {index === steps.length - 1 ? "Finish" : "Continue"}
                  </Button>
                  <Button
                    disabled={index === 0}
                    onClick={handleBack}
                    sx={{ mt: 1, mr: 1 }}
                  >
                    Back
                  </Button>
                </div>
              </Box>
            </StepContent>
          </Step>
        ))}
      </Stepper>
      {activeStep === steps.length && (
        <Paper square elevation={0} sx={{ p: 3, mb: 5 }}>
          <Typography fontSize={14}>
            All steps completed - you&apos;re finished
          </Typography>
          <Button
            variant="contained"
            onClick={handleReset}
            sx={{ mt: 1, mr: 1 }}
          >
            Reset
          </Button>
        </Paper>
      )}
      {stepsCompleted ? (
        <LineChart
          selectedTenantName={selectedTenant.tenantName}
          dateRange={monthRange}
          stepsCompleted={stepsCompleted}
        />
      ) : null}
    </Box>
  );
};

export default PrivilegeCreep;
