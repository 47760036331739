import React, { useEffect, useState } from "react";

import {
  MenuItem,
  Select,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Box,
  Paper,
  FormControl,
} from "@mui/material";
import MyHeaderTableCell from "../../Components/MyHeaderTableCell";
import axios from "axios";
import { NGROK } from "../../../../APIs";
import GroupTimer from "./GroupTimer";
import RadioAuthFilter from "../../../components/RadioAuthFilter";
import useGeneralSearch from "../../../../hooks/useGeneralSearch";
import SearchField from "../../logs/components/SearchField";

const GroupsTable = ({ userId, tenantName }) => {
  const [userGroups, setUserGroups] = useState([]);
  const [filterGroupValue, setFilterGroupValue] = useState("ALL");

  const {
    searchTerm,
    filteredData: groupList,
    handleSearch,
  } = useGeneralSearch(userGroups);

  const changeGroupStatusOnUi = (newGroup) => {
    const updatedGroups = groupList.map((group) =>
      group.adUser.id === newGroup.adUser.id &&
      group.adUserGroup.id === newGroup.adUserGroup.id
        ? newGroup
        : group
    );

    setUserGroups(updatedGroups);
  };

  const updateGroupStatus = async (group, groupStatus, selectedTime) => {
    const timeInSeconds = 60 * 60;

    try {
      const response = await axios.put(
        `${NGROK}/api/${tenantName}/user-groups/membership`,
        {
          userId,
          groupId: group.adUserGroup.id,
          groupStatus,
          selectedTime: timeInSeconds,
        }
      );

      if (!response.data) {
        changeGroupStatusOnUi(group);
      } else
        changeGroupStatusOnUi({ ...group, groupStatus, remainingTime: 3600 });
    } catch (error) {
      changeGroupStatusOnUi(group);
    }
  };

  useEffect(() => {
    const fetchGroups = async () => {
      try {
        const response = await axios.get(
          `${NGROK}/api/${tenantName}/user-groups/membership/${userId}`
        );

        const modifiedGroups = response.data?.map((group) => ({
          ...group,
          ...group.adUserGroup,
        }));

        setUserGroups(modifiedGroups);
      } catch (error) {
        setUserGroups([]);
        console.error(error);
      }
    };

    if (userId && tenantName) fetchGroups();
  }, [setUserGroups, tenantName, userId]);

  const checkIfTimeShouldBeShown = (group) =>
    group.groupStatus === "DYNAMIC" ? true : false;

  const handleFilterResourceValue = (e) => {
    setFilterGroupValue(e.target.value);
  };

  const groups = groupList?.filter((group) => {
    if (filterGroupValue === "ALL") return group;
    else {
      const trustLevel = {
        ALLOWED: "ENABLED",
        DENIED: "DISABLED",
        TIMED: "DYNAMIC",
      };

      return group.groupStatus === trustLevel[filterGroupValue];
    }
  });

  return (
    <Stack spacing={2}>
      <Box
        display={"flex"}
        justifyContent={"space-between"}
        width={"100%"}
        sx={{ padding: "10px" }}
      >
        <RadioAuthFilter
          filterResourceValue={filterGroupValue}
          filterName={"Filter by privilege"}
          handleFilterResourceValue={handleFilterResourceValue}
        />
        <FormControl sx={{ height: 50 }}>
          {" "}
          <SearchField searchTerm={searchTerm} handleSearch={handleSearch} />
        </FormControl>
      </Box>
      <Typography variant="h6" color="initial" fontWeight={600} align="center">
        Number of elements: {groups?.length || "0"}
      </Typography>

      <TableContainer component={Paper}>
        <Table
          sx={{
            width: "100%",
            height: "fit-content",
            "& td, & th": {
              border: "1px solid #233044",
            },
          }}
        >
          <TableHead>
            <TableRow>
              <MyHeaderTableCell
                sx={{ fontSize: 18 }}
                align={"center"}
                className={"userName"}
              >
                Group
              </MyHeaderTableCell>
              <MyHeaderTableCell
                sx={{ fontSize: 18 }}
                align={"center"}
                className={"userName"}
              >
                Trust level
              </MyHeaderTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {userId &&
              groups?.map((group) => (
                <TableRow className="groupRow" key={`${group.adUserGroup.cn}`}>
                  <TableCell
                    sx={{ fontSize: 18 }}
                    component="td"
                    scope="row"
                    className="groupRowName userName"
                    title={group.adUserGroup.cn}
                  >
                    {group.adUserGroup.cn}
                  </TableCell>
                  <TableCell>
                    <Box className="profilePrivilegeLevelTableCell">
                      <Select
                        onChange={(event) =>
                          updateGroupStatus(group, event.target.value)
                        }
                        sx={{ minWidth: "100px" }}
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={group.groupStatus}
                        size="small"
                      >
                        <MenuItem value={"ENABLED"}>ALLOWED</MenuItem>
                        <MenuItem value={"DISABLED"}>DENIED</MenuItem>
                        <MenuItem value={"DYNAMIC"}>TIMED</MenuItem>
                      </Select>
                      {checkIfTimeShouldBeShown(group) ? (
                        <GroupTimer seconds={group.remainingTime} />
                      ) : null}
                    </Box>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Stack>
  );
};

export default GroupsTable;
