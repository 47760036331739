import React, { useEffect } from "react";
import styled from "styled-components/macro";
import { CircularProgress } from "@mui/material";
import { myLocalStorage } from "./StorageHelper";
import { useNavigate } from "react-router-dom";
import { useKeycloakStore } from "../services/UserService";

const Root = styled.div`
  justify-content: center;
  align-items: center;
  display: flex;
  min-height: 100vh;
`;

function Loader() {
  const navigate = useNavigate();
  const { local_kc } = useKeycloakStore();

  const access_token = myLocalStorage.getItem("access_token");

  useEffect(() => {
    if (access_token || local_kc?.authenticated) navigate("/dashboard/overview");
  }, [access_token, local_kc, navigate]);

  return (
    <Root>
      <CircularProgress color="secondary" />
    </Root>
  );
}

export default Loader;
